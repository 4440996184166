<template>
  <v-data-table
    :items="data?.items"
    :headers="data?.headers"
    :items-per-page="50"
    :sort-by="computedDefaultSortBy"
    :sort-desc="[true]"
    :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
    :custom-sort="_$smartCustomTableSort"
    multi-sort
  >
    <template
      v-for="{ value: key } in data?.headers"
      #[`item.${key}`]="{ value, item }"
    >
      <template v-if="key === 'phrase'">
        <div style="min-width: 320px" :key="key">
          {{ value }}
        </div>
      </template>
      <template v-else-if="!['volume', 'phrase'].includes(key)">
        <positions-value
          :key="key"
          :traffic="{ diff: item['_' + key]?.diff, our: value }"
        />
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
  </v-data-table>
</template>

<script>
import PositionsValue from "../TablesGroup/PositionsValue/index.vue";
import TableSorting from "../../../mixins/TableSorting";

export default {
  mixins: [TableSorting],
  props: {
    data: Object,
  },
  components: {
    PositionsValue,
  },
  computed: {
    computedDefaultSortBy() {
      return ["volume"];
      // const match = this.data.headers.find(
      //   (header) => header.text === this.$moment().format("DD MMM")
      // );
      //
      // if (!match) return [];
      //
      // return [match.value];
    },
  },
};
</script>
