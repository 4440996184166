<template>
  <div class="worksection-daily">
    <v-row>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          Worksection Daily Report
        </page-name>
      </v-col>
    </v-row>
    <template v-if="totalTime || loadings.table">
      <v-row
        class="worksection-daily__radial-graphs"
        dense
        v-if="!loadings.table"
      >
        <v-col cols="3" class="graph__item">
          <template v-if="done_week.A && done_week.A.series[0] >= 0">
            <span class="text-center">A</span>
            <RadialBarChart :series="done_week.A.series"></RadialBarChart>
          </template>
          <template v-else>
            <div style="opacity: 0.3">
              <span class="text-center">A</span>
              <RadialBarChart :series="[0]"></RadialBarChart>
            </div>
          </template>
        </v-col>
        <v-col cols="3" class="graph__item">
          <template v-if="done_week.B && done_week.B.series[0] >= 0">
            <span class="text-center">B</span>
            <RadialBarChart :series="done_week.B.series"></RadialBarChart>
          </template>
          <template v-else>
            <div style="opacity: 0.3">
              <span class="text-center">B</span>
              <RadialBarChart :series="[0]"></RadialBarChart>
            </div>
          </template>
        </v-col>
        <v-col cols="3" class="graph__item">
          <template v-if="done_week.C && done_week.C.series[0] >= 0">
            <span class="text-center">C</span>
            <RadialBarChart :series="done_week.C.series"></RadialBarChart>
          </template>
          <template v-else>
            <div style="opacity: 0.3">
              <span class="text-center">C</span>
              <RadialBarChart :series="[0]"></RadialBarChart>
            </div>
          </template>
        </v-col>
        <v-col cols="3" class="graph__item">
          <div class="worksection-daily__total">
            <div class="total__wrapper">
              <small>Total:</small>
              {{ totalTime === "0:0" ? "-" : totalTime }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col cols="3" class="worksection-daily__skeleton">
          <v-skeleton-loader type="image" height="133" />
        </v-col>
        <v-col cols="3" class="worksection-daily__skeleton">
          <v-skeleton-loader type="image" height="133" />
        </v-col>
        <v-col cols="3" class="worksection-daily__skeleton">
          <v-skeleton-loader type="image" height="133" />
        </v-col>
        <v-col cols="3" class="worksection-daily__skeleton last">
          <v-skeleton-loader type="button" min-width="120" />
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col cols="12">
        <div class="worksection-daily__filters custom-bordered-wrapper">
          <v-row dense>
            <v-col cols="2">
              <v-autocomplete
                v-model="executive"
                label="Executive"
                :items="filtersData.executive"
                item-text="email"
                item-value="email"
                outlined
                multiple
                dense
                clearable
                :loading="loadings.filters"
                @change="
                  handleFilterChange($event, FILTERS_QUERY_IDS.executive)
                "
                :menu-props="menuProps"
                prepend-inner-icon="mdi-account-outline"
                hide-details
                class="small-chips"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="label"
                label="Label"
                multiple
                :items="filtersData.label"
                item-text="title"
                item-value="title"
                outlined
                dense
                clearable
                :loading="loadings.filters"
                @change="handleFilterChange($event, FILTERS_QUERY_IDS.label)"
                :menu-props="menuProps"
                hide-details
                prepend-inner-icon="mdi-filter-outline"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="slide-x-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Select day"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    dense
                    clearable
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  :reactive="true"
                  @change="$refs.menu.save(date), (dateMenu = false)"
                />
              </v-menu>
            </v-col>
            <v-col class="d-flex justify-end align-center">
              <v-btn
                color="primary"
                @click="handleGetData"
                :loading="loadings.table"
              >
                Get data
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="worksection-daily__cards">
          <div v-if="cardsLength > 0 && !loadings.table" class="cards__wrapper">
            <v-text-field
              label="Search"
              placeholder="Enter something"
              dense
              hide-details
              append-icon="mdi-magnify"
              v-model="searchModel"
              style="max-width: 350px; margin-bottom: 25px"
            ></v-text-field>
            <div
              v-for="(key, index) in Object.keys(cards)"
              :key="key + index"
              class="card__wrapper"
            >
              <div class="table-grey-wrapper">
                <div class="card__header">
                  <h2 style="text-transform: capitalize">
                    {{ cards[key].title }}
                  </h2>
                  <div class="sort-by-filter-button" @click="handleSortByDone">
                    Sort by status
                    <v-icon v-if="sortByDoneStatus === 1" small
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon v-if="sortByDoneStatus === 2" small
                      >mdi-chevron-up</v-icon
                    >
                  </div>
                  <div class="card__total">
                    <small>total:</small>
                    {{ cards[key].total === "00:00" ? "-" : cards[key].total }}
                  </div>
                </div>
                <v-data-table
                  :search="searchModel"
                  :headers="tableData.headers"
                  :items="cards[key].items"
                  :items-per-page="-1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  hide-default-footer
                  :custom-sort="customSort"
                  multi-sort
                  class="card__table"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="(tr, index) in items"
                        :key="index"
                        :class="getTrClasses(tr)"
                      >
                        <td>{{ tr.project_name }}</td>
                        <!--project-->
                        <td-name :data="tr" />
                        <!--name-->
                        <td>{{ tr.priority }}</td>
                        <!--pr-->
                        <td-time :data="tr" />
                        <!--time-->
                        <td>{{ tr.max_time }}</td>
                        <!--E-->
                        <td-day :data="tr" valName="d1" />
                        <!--FSS-->
                        <td-day :data="tr" valName="d2" />
                        <!--M-->
                        <td-day :data="tr" valName="d3" />
                        <!--T-->
                        <td-day :data="tr" valName="d4" />
                        <!--W-->
                        <td-day :data="tr" valName="d5" />
                        <!--T-->
                        <td-day :data="tr" valName="d6" />
                        <!--F-->
                        <td>{{ tr.executive }}</td>
                        <!--executive-->
                        <td>{{ tr.priority_project }}</td>
                        <!--pr_pr-->
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
          <template v-else-if="loadings.table">
            <div class="mt-3 pa-3" style="position: relative">
              <loader :show="loadings.table" />
              <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
            </div>
          </template>
          <div
            v-else
            class="placeholder-text__wrapper d-flex justify-center align-center"
          >
            <div class="placeholder">
              <span
                v-html="
                  firstEnter ? tooltips.itemsPlaceholder : tooltips.firstEnter
                "
              ></span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RadialBarChart from "@/components/Ltt/Dashboards/WorksectionWeek/RadialBarChart";
import PageName from "@/components/DashboardDev/PageName";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import Location from "@/mixins/Location";
import Loader from "@/components/Main/Loader";
import TdDay from "@/components/Ltt/Dashboards/WorksectionDailyReportV2/td/TdDay";
import TdTime from "@/components/Ltt/Dashboards/WorksectionDailyReportV2/td/TdTime";
import TdName from "@/components/Gpm/OutreachProblems/td/TdName";

export default {
  components: { TdName, TdTime, TdDay, PageName, Loader, RadialBarChart },
  mixins: [Location],
  created() {
    this.fetchFilters();
    const { haveDate } = this.collectParamsFromQuery();

    if (!haveDate) {
      this.date = String(
        this.$moment().subtract(1, "day").format("YYYY-MM-DD")
      );
    }

    this.fetchData();
  },
  data: () => ({
    searchModel: "",
    sortBy: ["status", "priority_project", "priority"],
    sortDesc: [true, true, true],
    dateMenu: false,
    loadings: {
      table: false,
      filters: false,
    },
    menuProps: DEFAULT_MENU_PROPS,
    filtersData: {
      executive: [],
    },
    FILTERS_QUERY_IDS: {
      executive: "executive",
      label: "label",
      date: "date",
    },
    executive: [], // filter
    label: [], // filter
    date: [], // filter
    cards: [],
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    tableData: {
      headers: [],
    },
    cardsLength: 0,
    firstEnter: false,
    done_week: {
      A: {
        series: [-1],
      },
      B: {
        series: [-1],
      },
      C: {
        series: [-1],
      },
    },
    totalTime: 0,
    sortByDoneStatus: 0,
  }),
  watch: {
    date(date) {
      this.handleFilterChange(date, this.FILTERS_QUERY_IDS.date);
    },
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      const text = ["project"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]].name === null) aVal = 0;
            if (b[sortBy[i]].name === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]].name;
            const val2 = b[sortBy[i]].name;
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    handleSortByDone() {
      if (this.sortByDoneStatus === 0) {
        this.sortBy.push("status");
        this.sortDesc.push(true);
        return (this.sortByDoneStatus = 1);
      }
      if (this.sortByDoneStatus === 1) {
        const idxOfFilter = this.sortBy.findIndex((item) => item === "status");
        this.sortDesc.splice(idxOfFilter, 1, false);
        return (this.sortByDoneStatus = 2);
      }
      const idxOfFilter = this.sortBy.findIndex((item) => item === "status");
      this.sortBy = this.sortBy.filter((item) => item !== "status");
      this.sortDesc.splice(idxOfFilter, 1);
      return (this.sortByDoneStatus = 0);
    },
    getTrClasses(tr) {
      if (tr.scheduled_for_yesterday === 1) {
        return "not-done";
      }
      if (tr.status === 1) {
        return "done";
      }
      return "";
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (key === this.FILTERS_QUERY_IDS.date) {
          return (this[key] = value);
        }
        return (this[key] = value.split(","));
      });

      const haveDate = keys.includes(this.FILTERS_QUERY_IDS.date);

      if (keys.length > 0) {
        return { haveParams: true, haveDate };
      }
      return false;
    },
    handleUpdateDateFilter(event) {
      this.date = event;
      if (!event.length) {
        event = null;
      }
      this.handleFilterChange(event, this.FILTERS_QUERY_IDS.date);
    },
    sortCards(items) {
      const newItems = [];
      Object.keys(items).forEach((key) => {
        const item = items[key];
        const fixedKey = key.toLowerCase();
        item.title = key;
        if (fixedKey === "a") {
          return (newItems[0] = item);
        }
        if (fixedKey === "b") {
          return (newItems[1] = item);
        }
        if (fixedKey === "c") {
          return (newItems[2] = item);
        }
        if (fixedKey === "meeting") {
          return (newItems[3] = item);
        }
        if (fixedKey === "regular") {
          return (newItems[4] = item);
        }
        if (fixedKey === "other") {
          return (newItems[5] = item);
        }
        return newItems.push(item);
      });
      return newItems;
    },
    calculateTotalTime(items = []) {
      let durations = [];
      let total = 0;
      const moment = this.$moment;

      Object.keys(items).forEach((key) => {
        durations.push(items[key].total);
      });

      durations.forEach((time) => {
        const timeParts = time.split(":");
        total += +timeParts[0] * (60000 * 60) + +timeParts[1] * 60000;
      });

      return (
        Math.trunc(moment.duration(total).asHours()) +
        ":" +
        moment.duration(total).minutes()
      );
    },
    async fetchData() {
      this.firstEnter = true;
      this.loadings.table = true;
      const { executive, label, date } = this;
      let payload = {
        filter: { executive, label, date },
      };

      const data = await this.$store.dispatch(
        "worksectionReportDaily/fetchData",
        payload
      );
      if (!data) {
        return (this.loadings.table = false);
      }
      this.tableData.headers = data.table.headers;
      this.cards = this.sortCards(data.table.items);
      this.cardsLength = Object.keys(this.cards).length;
      this.done_week = data.done_week;

      this.totalTime = this.calculateTotalTime(data.table.items);
      this.loadings.table = false;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const resp = await this.$store.dispatch(
        "worksectionReportDaily/fetchFilters",
        {}
      );
      if (resp) {
        this.filtersData.executive = resp.executives;
        this.filtersData.label = resp.labels;
      }
      this.loadings.filters = false;
    },
    handleGetData() {
      this.fetchData();
    },
    handleFilterChange(newValue, type) {
      const isArray = Array.isArray(newValue);
      if (isArray) {
        newValue = newValue.map((item) => {
          if (item === null) {
            item = "null";
          }
          return String(item);
        });
      }
      if (isArray && newValue.length === 0) {
        newValue = null;
      }
      this._$setQueryParams(type, newValue);
    },
  },
};
</script>
