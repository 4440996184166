import { render, staticRenderFns } from "./UserDetailsPieAndGraph.vue?vue&type=template&id=585d5a6e&"
import script from "./UserDetailsPieAndGraph.vue?vue&type=script&lang=js&"
export * from "./UserDetailsPieAndGraph.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports