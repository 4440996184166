<template>
  <Search />
</template>

<script>
import Search from "@/components/ViewInfoBySite/Search";

export default {
  name: "Index",
  components: { Search },
};
</script>

<style scoped></style>
