<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12" md="12">
          <v-card
              class="mx-auto"
              v-if="isset_active_project"
          >
            <v-card-text>
              <h3>Anchors Plan Dashboard : </h3>
              <div>
                <v-row
                    class="align-end"
                >
                  <v-col
                      sm="11"
                  >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        solo
                        class="align-end"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-menu
                        transition="slide-x-transition"
                        :close-on-content-click="false"
                        :nudge-right="200"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-fab-transition>
                          <v-btn
                              color="info"
                              dark
                              fab
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-icon>mdi-filter</v-icon>
                          </v-btn>
                        </v-fab-transition>
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-select
                                  @change="changeUrl"
                                  v-model="filter.project"
                                  :items="getProjects"
                                  item-text="name"
                                  item-value="id"
                                  label="Project"
                                  solo
                                  clearable
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-select
                                  @change="changeUrl"
                                  v-model="filter.type"
                                  :items="types_items"
                                  item-text="name"
                                  item-value="id"
                                  label="Type"
                                  solo
                                  clearable
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-select
                                  @change="changeUrl"
                                  v-model="filter.performer"
                                  :items="performers"
                                  item-text="name"
                                  item-value="id"
                                  label="Performer"
                                  solo
                                  clearable
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-select
                                  @change="changeUrl"
                                  v-model="filter.level"
                                  :items="levels"
                                  item-text="name"
                                  item-value="id"
                                  label="Level"
                                  solo
                                  clearable
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-dialog
                                  ref="dialog"
                                  v-model="date_modal"
                                  persistent
                                  width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="filter.date_range"
                                      @change="changeUrl"
                                      label="Picker in dialog"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filter.date_range"
                                    range
                                    scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      text
                                      color="primary"
                                      @click="date_modal = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                      text
                                      color="primary"
                                      @click="date_modal = false"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-list-item>


                          </v-list>
                          <div class="text-center">
                            <v-btn
                                @click="fetch_data()"
                                color="primary">
                              Apply
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row
                    align="center"
                    justify="center"
                >
                  <v-col>
                    <v-data-table
                        :search="search"
                        :headers="table.headers"
                        :items="table.items"
                        dense
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="text-left" style="background-color: white">
                            {{ item.project }}
                          </td>
                          <td class="text-left" style="background-color: white">
                            {{ item.type }}
                          </td>
                          <td class="text-left" style="background-color: white">
                            {{ item.metric }}
                          </td>
                          <td class="text-center" v-for="date in table.dates" :key="date">
                            <span v-if="item[date.full].date>0">
                              <span v-if="item[date.full].diff>0 || item[date.full].diff<0">
                                 <v-badge
                                     :color="getDiffColor(item[date.full].diff,item.metric)"
                                     :content="getDiffContent(item[date.full].diff)"
                                     overlap
                                     bordered
                                 >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                      label
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                  >
                                    {{ item[date.full].date }}
                                  </v-chip>
                                </template>
                                <span>{{ item[date.full].date }}</span>
                              </v-tooltip>
                            </v-badge>
                            </span>
                              <span v-else>
                                  <v-chip
                                      label
                                      small
                                  >
                                    {{ item[date.full].date }}
                                  </v-chip>
                              </span>
                              </span>
                          </td>
                          <td class="text-left">
                            {{ item.performer }}
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>


  </div>
</template>

<script>

import axios from "axios";

let dateFormat = require('dateformat');
let date = new Date();

export default {
  name: "AnchorPlanDashboardIndex",
  metaInfo: {
    title: 'Anchor Plan Dashboard - LTT - SI'
  },
  data() {

    return {
      search: "",
      loadings: {},
      date_modal: false,
      table: {
        items: [],
        headers: [],
        dates: []
      },
      types_items: [],
      levels: [
        {id: 1, name: '1 level'},
        {id: 2, name: '2 level'}
      ],
      performers: [],
      filter: {
        date_range: [
          dateFormat(new Date().setMonth(date.getMonth() - 1), "yyyy-mm-dd"),
          dateFormat(date, "yyyy-mm-dd")
        ],
        type: parseInt(this.$route.query['type'] ?? undefined),
        project: parseInt(this.$route.query['project'] ?? undefined),
        level: parseInt(this.$route.query['level'] ?? 1),
        performer: parseInt(this.$route.query['performer'] ?? undefined)
      }
    }
  },
  methods: {
    getDiffColor(diff, name) {
      if (name === 'problematic') {
        if (diff > 0) {
          return 'red'
        } else return '#d7d700'
      } else {
        if (diff > 0) {
          return '#d7d700'
        }
      }
      return 'grey'
    },
    getDiffContent(diff) {
      if (diff > 0) {
        return '+' + diff
      } else return diff
    },
    fetch_types_list() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('types_list', true)

        let url = `${self.$store.state.server_url}/ltt/links-type`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        axios.get(url, config)
            .then(function (response) {
              self.types_items = response.data
              self.update_loading('types_list', false)
            }).catch(function (error) {
          alert(error);
        })
      }
    },
    changeUrl() {
      this.$router.push({
        name: 'anchors-plan.dashboard', query: {
          type: this.filter.type,
          project: this.filter.project,
          level: this.filter.level,
          performer: this.filter.performer
        }
      })
    },
    fetch_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_data', true)

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/dashboard`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {
          filter: this.filter
        }


        //  location.reload()

        self.table.items = []

        axios.post(url, data, config)
            .then(function (response) {
              let table = response.data.table
              self.table.items = table.items
              self.table.headers = table.headers
              self.table.dates = table.dates
              self.performers = response.data.performers
              self.update_loading('fetch_data', false)


            }).catch(function (error) {
          alert(error);
        })
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    getProjects() {
      return this.$store.state.project.allowed_projects
    },
  },
  created() {
    let self = this;

    self.fetch_types_list()
    self.fetch_data()
  },
}
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  min-width: 130px;
  z-index: 9998;
}

table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 130px;
  min-width: 90px;
  z-index: 9998;
}

table > tbody > tr > td:nth-child(3),
table > thead > tr > th:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 220px;
  min-width: 90px;
  z-index: 9998;
}


</style>