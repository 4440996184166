<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-autocomplete
          :items="countries"
          v-model="country"
          item-text="name"
          item-value="id"
          dense
          label="Country"
          placeholder="Type to search"
          hide-details
          outlined
          :menu-props="menuProps"
          style="max-width: 175px"
        >
        </v-autocomplete>
        <v-form @submit="openDomain" class="ml-2">
          <v-text-field
            v-model="domain"
            dense
            hide-details
            outlined
            label="Domain"
            placeholder="Enter domain"
            append-icon="mdi-magnify"
            @click:append="openDomain"
            name="domain"
          />
        </v-form>
        <a
          v-if="domain"
          :href="`http://${domain}`"
          target="_blank"
          class="reset-link-styles ml-1"
        >
          <v-btn icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </a>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-chip v-if="queueTotal" outlined small>
          Domains in queue: <b class="ml-2">{{ queueTotal }}</b>
        </v-chip>
        <v-chip v-if="waitingTime" outlined small class="ml-2">
          Waiting time: <b class="ml-2">{{ waitingTime }}</b>
        </v-chip>
        <a
          :href="`https://app.ahrefs.com/site-explorer/overview/v2/subdomains/live?target=${domain}`"
          target="_blank"
          class="reset-link-styles ml-2"
        >
          <v-chip outlined color="primary" small @click="() => {}">
            visit Ahrefs
          </v-chip>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";

export default {
  name: "Search",
  props: ["queueTotal", "waitingTime"],
  data() {
    return {
      menuProps: DEFAULT_MENU_PROPS,
      domain: this.$route.query["domain"],
      country: parseInt(this.$route.query["country"] ?? 200),
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    countries() {
      return this.$store.state.reuse.countries;
    },
  },
  methods: {
    openDomain() {
      this.$router.push({
        name: "view.info.by.site.show",
        query: {
          domain: this.domain,
          country: this.country,
        },
      });
      location.reload();
    },
  },
  created() {
    // if (this.countries.length === 0) {
    this.$store.dispatch("fetch_countries_list");
    // }
  },
};
</script>

<style scoped></style>
