<template>
  <td style="text-align:center;">
    <div>
      {{getContent}}
    </div>
  </td>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    getContent() {
      let value = this.data.week
      if(value === '00:00') value = ''
      return value
    }
  },
}
</script>
