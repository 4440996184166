<template>
  <v-dialog
    v-model="dialog"
    content-class="remove-dialog-shadow"
    scrollable
    width="600"
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Add new task to export
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" class="mb-4 d-flex align-center">
            <v-autocomplete
              v-model="export_type"
              :items="listOfTypes"
              :loading="loadings.filters"
              filled
              clearable
              hide-details
              dense
              label="Export type"
              style="flex: 1"
              @change="handleChangeTypeOfExport"
            ></v-autocomplete>
            <div
              style="flex: 0 1 42px"
              class="d-flex justify-center align-center"
            >
              <v-btn
                icon
                @click="handleChangeTypeOfExport"
                :loading="loadings.filtersConfig"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </v-col>
          <template>
            <Filters
              :model-value="filters"
              @update:modelValue="filters = $event"
              :groups="filterGroups"
              :errors="filterErrors"
            />
          </template>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-center">
        <v-btn
          width="180"
          color="success"
          :loading="loadings.savingNewTask"
          :disabled="!export_type"
          @click="handleSaveNewTaskToExport(filters)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Filters from "@/components/FiltersFactory/Filters.vue";
import Dialog from "@/mixins/Dialog";
import ExportTaskService from "@/services/ExportTaskService";
import service from "@/plugins/service";
import FiltersService from "@/services/FiltersService";

export default {
  components: { Filters },
  mixins: [Dialog],
  data: () => ({
    filters: null,
    export_type: null,
    listOfTypes: null,
    filterErrors: null,
    filterGroups: null,
    loadings: {
      filtersConfig: null,
    },
    exportFormGroupConfig: {
      groups: {
        mainFilters: {
          name: "Export configurating",
          filters: [
            "export_type",
            "ah_traffic_min",
            "project",
            "country_lang",
            "country",
            "semantic_tool_task",
            "sl_task",
            "phrases",
            "niche",
          ],
        },
      },
    },
  }),
  created() {
    this.fetchListOfTypes();
  },
  methods: {
    async fetchFiltersConfigs(type) {
      try {
        const url = `/export/report/${type}/config`;

        this.loadings.filtersConfig = true;

        const response = await service.get(url);

        const filtersData = new FiltersService(
          response.data.filters,
          this.exportFormGroupConfig
        );

        this.filterGroups = filtersData.groups;
        this.filters = filtersData.filters;
      } catch (e) {
        console.error(
          "Error while loading filters for export form by type.",
          e
        );
      } finally {
        this.loadings.filtersConfig = false;
      }
    },
    clearFiltersData() {
      this.filters = {};
      this.filterGroups = {};
    },
    async fetchListOfTypes() {
      try {
        const url = "/export/report";

        const response = await service.get(url);

        if (response) {
          this.listOfTypes = response.data.items;
        }
      } catch (e) {
        console.error("Error while loading list of types.", e);
      }
    },
    handleChangeTypeOfExport() {
      if (!this.export_type) return this.clearFiltersData();
      this.clearFiltersData();
      this.fetchFiltersConfigs(this.export_type);
    },
    async handleSaveNewTaskToExport(data) {
      if (!this.export_type) {
        return this.$message.notification({
          title: "Validation error",
          text: "Select export type.",
          type: "warning",
        });
      }

      const exportService = new ExportTaskService({
        type: this.export_type,
        json: { ...data },
        context: this,
      });

      this.loadings.savingNewTask = true;

      const response = await exportService.createExport();

      if (response.status) {
        this.clearFiltersData();
        this.addNewTaskDialogModel = false;
      } else {
        this.filterErrors = response.error.errors || {};
      }

      this.loadings.savingNewTask = false;
    },
  },
};
</script>
