<template>
  <v-container fluid class="contentball-analytics">
    <v-row dense>
      <v-col>
        <h2>ContentBall Analytics</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex mb-2 align-end">
          <table-search
            :model-value="search"
            @update:modelValue="search = $event"
            style="max-width: 280px"
            placeholder="Type to search in table"
          />
          <v-chip
            v-if="selectedTableItems.length > 0"
            class="ml-2"
            label
            outlined
            @click="handleSendOnRecalculate"
          >
            Recalculate Selected
            <v-icon small right>mdi-calculator-variant-outline</v-icon>
          </v-chip>
          <v-spacer />

          <a
            href="https://docs.google.com/spreadsheets/d/18YdfVCMS3GgXZr47eggJwB0twUV2lLCHEO06vVebe6Q/edit#gid=872306655"
            target="_blank"
            class="mr-2"
          >
            <v-chip
              style="border-style: dashed"
              outlined
              label
              @click="() => {}"
              >View structure
              <v-icon right small>mdi-open-in-new</v-icon>
            </v-chip>
          </a>
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="false"
            offset-y
            content-class="my-shadow--e3"
          >
            <template v-slot:activator="{ on }">
              <filters-chip
                v-on="on"
                :filters="filters"
                clearable
                @click:clear="handleClearFilters"
              />
            </template>
            <v-card class="styled-card--default" width="380px">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filters.niche"
                      :items="filtersData.niche"
                      label="Niche"
                      hide-details
                      dense
                      filled
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filters.team"
                      :items="filtersData.team"
                      label="Team"
                      hide-details
                      dense
                      filled
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filters.main_seo"
                      :items="filtersData.main_seo"
                      label="Main SEO"
                      hide-details
                      dense
                      filled
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filters.project"
                      :items="filtersData.project"
                      label="Site"
                      hide-details
                      dense
                      filled
                    />
                  </v-col>
                  <v-col cols="12">
                    <easy-range-input
                      label="Need top"
                      filled
                      :model-value="filters.need_top"
                      @update:modelValue="filters.need_top = $event"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filters.subproject"
                      :items="filtersData.subproject"
                      label="Subproject"
                      hide-details
                      dense
                      filled
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn
                  block
                  @click="fetchData"
                  color="primary"
                  width="140px"
                  :loading="loadings.tables"
                >
                  Get data
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-chip
            label
            class="ml-2 px-0 d-flex justify-center"
            style="width: 32px"
            color="primary"
            title="refresh data"
            @click="fetchData"
          >
            <v-icon v-if="!loadings.table" small>mdi-refresh</v-icon>
            <v-progress-circular v-else size="18" width="2" indeterminate />
          </v-chip>
        </div>
        <v-card class="styled-card--default">
          <v-card-text class="pa-0">
            <template v-if="!loadings.table">
              <template v-if="items && items.length > 0">
                <v-data-table
                  v-model="selectedTableItems"
                  show-select
                  :search="search"
                  :headers="getHeaders"
                  :items="items"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  item-key="url"
                  :custom-sort="customSort"
                  multi-sort
                  :items-per-page="50"
                  :footer-props="{
                    itemsPerPageOptions: [25, 50, 100, 200, 500, -1],
                  }"
                  class="contentball-analytics__table"
                >
                  <template
                    v-for="h in getHeaders"
                    #[`header.${h.value}`]="{ header }"
                  >
                    <template v-if="h.title">
                      <v-tooltip bottom :key="h.text">
                        <template #activator="{ on }">
                          <span v-on="on">{{ header.text }} </span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <span :key="h.value">{{ h.text }}</span>
                    </template>
                  </template>
                  <template #body="{ items }">
                    <tbody>
                      <tr
                        v-for="(row, index) in items"
                        :key="index"
                        :class="`${
                          String(row.text_size_our) === '0' ? 'marked' : ''
                        }`"
                      >
                        <td>
                          <v-checkbox
                            v-model="selectedTableItems"
                            :value="row"
                            :key="row.url"
                            hide-details
                            dense
                            class="ma-0"
                          ></v-checkbox>
                        </td>
                        <template v-for="header in getHeaders">
                          <template v-if="smartHoverTds.includes(header.value)">
                            <smart-hover-td
                              :key="header.value"
                              :data="row[header.value]"
                              @show="handleShowUrls($event, row)"
                              :content="row"
                              :name="header.value"
                            />
                          </template>
                          <template v-else-if="smartTds.includes(header.value)">
                            <smart-td
                              :key="header.value"
                              :data="row[header.value]"
                              @show="handleShowDetail"
                              :name="header.value"
                            />
                          </template>
                          <template v-else-if="deltaTds.includes(header.value)">
                            <smart-td
                              :disable-color="true"
                              :key="header.value.replace('_delta', '')"
                              :data="row[header.value.replace('_delta', '')]"
                              @show="handleShowDetail"
                              :name="header.value.replace('_delta', '')"
                              :force-value="row[header.value]"
                            />
                          </template>
                          <template
                            v-else-if="header.value === 'content_score'"
                          >
                            <smart-hover-td
                              :key="header.value"
                              :data="row[header.value]"
                              value-key="value"
                              @show="handleShowUrls($event, row)"
                              :content="row"
                              :name="header.value"
                            />
                          </template>
                          <template v-else-if="header.value === '_actions'">
                            <td :key="header.value">
                              <v-tooltip bottom :key="header.value">
                                <template #activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    icon
                                    @click="handleAddToRecalculate(row)"
                                    :loading="
                                      loadings.recalculate.includes(row.url)
                                    "
                                  >
                                    <v-icon
                                      >mdi-calculator-variant-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <div>Add to recalculate queue</div>
                              </v-tooltip>
                            </td>
                          </template>
                          <template v-else-if="header.value === 'stat_collect'">
                            <td :key="header.value">
                              <div
                                :class="`colored-td colored-td--inline ${
                                  row.serp !== row.texts ? 'red' : ''
                                }`"
                              >
                                <span
                                  v-if="row[header.value]"
                                  style="white-space: nowrap"
                                >
                                  {{
                                    Object.values(
                                      [
                                        row[header.value].serp,
                                        row[header.value].texts,
                                        row[header.value].valid_texts,
                                      ].filter((v) => !!v)
                                    ).join(" / ")
                                  }}
                                </span>
                              </div>
                            </td>
                          </template>
                          <template
                            v-else-if="
                              ['date', 'sw_deadline'].includes(header.value)
                            "
                          >
                            <td :key="header.value">
                              <v-tooltip
                                content-class="pa-0 remove-dialog-shadow"
                                bottom
                              >
                                <template #activator="{ on }">
                                  <div
                                    v-on="on"
                                    :class="`colored-td colored-td--inline ${
                                      getDaysDiff(row[header.value]) > 21
                                        ? 'red'
                                        : ''
                                    }`"
                                  >
                                    {{
                                      row[header.value]
                                        ? $moment(row[header.value]).format(
                                            "DD/MM/YYYY"
                                          )
                                        : ""
                                    }}
                                  </div>
                                </template>
                                <v-card class="pa-2">
                                  {{ $moment(row[header.value]).fromNow() }}
                                </v-card>
                              </v-tooltip>
                            </td>
                          </template>
                          <template
                            v-else-if="
                              ['cf_pr', 'sw_linking'].includes(header.value)
                            "
                          >
                            <td :key="header.value">
                              <div
                                :class="`colored-td colored-td--inline ${
                                  { publishing: 'orange', done: 'green' }[
                                    row[header.value]
                                  ]
                                }`"
                                style="white-space: nowrap"
                              >
                                {{ row[header.value] }}
                              </div>
                            </td>
                          </template>
                          <template v-else-if="header.value === 'sw_status'">
                            <td :key="header.value">
                              <div
                                :class="`colored-td colored-td--inline ${
                                  row[header.value] === 'to do'
                                    ? 'blue'
                                    : 'green'
                                }`"
                                style="white-space: nowrap"
                              >
                                {{ row[header.value] }}
                              </div>
                            </td>
                          </template>
                          <template
                            v-else-if="
                              ['m1', 'm2', 'm3', 'm4', 'm5', 'm6'].includes(
                                header.value
                              )
                            "
                          >
                            <td :key="header.value">
                              <table-select-chip
                                :value="row[header.value]"
                                @change="
                                  handleChangeMValue($event, header.value, row)
                                "
                                :items="[0, 1, 2]"
                                :loading="
                                  loadings.changeMValue.includes(row.id)
                                "
                              />
                            </td>
                          </template>
                          <template v-else-if="header.value === 'url'">
                            <td :key="header.value">
                              <span
                                style="
                                  display: inline-block;
                                  max-width: 240px;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  overflow: hidden;
                                "
                                :title="row[header.value]"
                              >
                                <div>
                                  <small
                                    style="line-height: 100%; opacity: 0.8"
                                  >
                                    {{ getUrlData(row[header.value]).host }}
                                  </small>
                                </div>
                                <a
                                  :href="row[header.value]"
                                  target="_blank"
                                  style="font-weight: 500"
                                >
                                  {{ getUrlData(row[header.value]).pathname }}
                                </a>
                              </span>
                            </td>
                          </template>
                          <template v-else>
                            <td :key="header.value">
                              {{ row[header.value] }}
                            </td>
                          </template>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div class="d-flex justify-center py-4">
                  <span style="opacity: 0.5">Nothing...</span>
                </div>
              </template>
            </template>
            <template v-else>
              <v-skeleton-loader type="table" />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <details-dialog
      :model-value="detailDialogModel"
      @update:modelValue="detailDialogModel = $event"
      :items="detailsData"
    />
    <activity-dialog
      :headers="activityTableHeaders"
      :items="urlItemsData"
      :selected-url="selectedUrlData?.url"
      :model-value="urlItemsDialogModel"
      @update:modelValue="urlItemsDialogModel = $event"
      @addToRecalculate="handleAddToRecalculate(selectedUrlData)"
    />
  </v-container>
</template>

<script>
import SmartTd from "./UI/SmartTd.vue";
import SmartHoverTd from "./UI/SmartHoverTd.vue";
import {
  ACTIVITY_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "../../utils/defaultData";
import getRgb from "../../mixins/GetRgb";
import service from "../../plugins/service";
import FiltersChip from "@/components/UI/FiltersChip.vue";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import ActivityDialog from "@/components/ContentballAnalytics/UI/ActivityDialog.vue";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import DetailsDialog from "@/components/ContentballAnalytics/DetailsDialog.vue";
import TableSelectChip from "@/components/ContentballAnalytics/UI/TableSelectChip.vue";
import TableSearch from "@/components/UI/TableSearch.vue";

export default {
  components: {
    TableSearch,
    TableSelectChip,
    DetailsDialog,
    EasyRangeInput,
    ActivityDialog,
    FiltersChip,
    SmartTd,
    SmartHoverTd,
  },
  mixins: [getRgb, FiltersHandling],
  data: () => ({
    filters: {
      niche: null,
      team: null,
      main_seo: null,
      project: null,
      need_top: [null, null],
    },
    filtersData: {
      niche: [],
      team: [],
      main_seo: [],
      site: [],
      subproject: [],
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Contentball analytic",
        disabled: true,
      },
    ],
    smartHoverTds: [
      "total_words",
      "total_sentences",
      "average_sentence_length",
      "semantic_vocabulary",
      "vocabulary",
      "water_index",
      "zipfs_law",
      "classical_nausea_index",
      "dale_chall_score",
      "spache",
      "flesch",
      "automated_index",
    ],
    smartTds: [
      "only_stop_words_1",
      "by_words_2_others",
      "only_stop_words_2",
      "main_phrases_by_vision",
      "main_words_by_vision",
      "snippet",
      "related",
      "suggest",
    ],
    deltaTds: [
      "only_stop_words_1_delta",
      "only_stop_words_2_delta",
      "by_words_2_others_delta",
    ],
    menuProps: DEFAULT_MENU_PROPS,
    selectedTableItems: [],
    headers: [],
    sortBy: ["pr_pr", "nt", "pr"],
    sortDesc: [true, false, false],
    items: [],
    detailSearch: "",
    detailDialogModel: false,
    urlItemsDialogModel: false,
    needToShowDetails: false,
    search: "",
    urlItemsData: [],
    selectedUrlData: null,
    activityTableHeaders: ACTIVITY_TABLE_HEADERS,
    modalDetailsTableData: [],
    loadings: {
      table: false,
      filters: false,
      recalculate: [],
      changeMValue: [],
      selectedRecalc: false,
    },
    detailsData: [],
    listOfDetailsCols: [
      "total_words",
      "total_sentences",
      "average_sentence_length",
      "semantic_vocabulary",
      "vocabulary",
      "water_index",
      "zipfs_law",
      "classical_nausea_index",
      "dale_chall_score",
      "spache",
      "flesch",
      "automated_index",
    ],
  }),
  created() {
    this.fetchFilters();

    this._$collectParamsTo(this, "filters", []);

    this.fetchData();
  },
  computed: {
    getHeaders() {
      return [...this.headers, { text: "", value: "_actions" }];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this._$handleFiltersChange(value);
      },
    },
  },
  methods: {
    getUrlData(urlString) {
      try {
        return new URL(urlString);
      } catch {
        return "Incorrect URL";
      }
    },
    async fetchFilters() {
      try {
        const filters = ["niche", "team", "main_seo", "site", "subproject"];
        const payload = {
          type: "/dashboards/cb/analytics",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.filters = false;
      }
    },
    handleClearFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
    },
    async handleChangeMValue(value, type, { id: urlId }) {
      try {
        const url = "/dashboards/cb/analytics/update-marker";

        const payload = {
          url: urlId,
          marker: type,
          value,
        };

        this.loadings.changeMValue.push(urlId);

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while changing M status.", e);
        throw e;
      } finally {
        this.loadings.changeMValue = this.loadings.changeMValue.filter(
          (v) => v !== urlId
        );
      }
    },
    getDaysDiff(date) {
      try {
        return this.$moment(date).diff(this.$moment(), "days");
      } catch {
        return 0;
      }
    },
    async handleSendOnRecalculate() {
      try {
        this.loadings.selectedRecalc = true;
        const urls = this.selectedTableItems.map((v) => v.id);

        const url = "/dashboards/cb/analytics/refresh-cache";

        const payload = {
          urls,
        };

        const resp = await service.post(url, payload);

        this.loadings.selectedRecalc = false;

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "All selected URL's Successfully sent for recalculation",
            type: "success",
          });
        }
      } catch {
        this.loadings.selectedRecalc = false;
      }
    },
    async handleAddToRecalculate(item) {
      try {
        const url = "/dashboards/cb/analytics/refresh-cache";

        const payload = {
          urls: [item.id],
        };

        this.loadings.recalculate.push(item.url);

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "URL Successfully sent for recalculation",
            type: "success",
          });
        }

        this.loadings.recalculate = this.loadings.recalculate.filter(
          (v) => v !== item.url
        );
      } catch {
        this.loadings.recalculate = this.loadings.recalculate.filter(
          (v) => v !== item.url
        );
      }
    },
    handleShowUrls(urlData, rowData) {
      this.urlItemsData = urlData.amountItems;
      this.selectedUrlData = rowData;
      this.urlItemsDialogModel = true;
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          /*eslint-disable*/
          try {
            const hasDeviation =
              a[sortBy[i]].hasOwnProperty("deviation") &&
              b[sortBy[i]].hasOwnProperty("deviation");
            const hasPercent =
              a[sortBy[i]].hasOwnProperty("percent") &&
              b[sortBy[i]].hasOwnProperty("percent");

            if (hasDeviation) {
              let aVal, bVal;
              if (
                a[sortBy[i]].deviation === null ||
                a[sortBy[i]].deviation === ""
              )
                aVal = 0;
              if (
                b[sortBy[i]].deviation === null ||
                b[sortBy[i]].deviation === ""
              )
                bVal = 0;
              if (aVal === 0 && bVal !== 0) return 1;
              if (bVal === 0 && aVal !== 0) return -1;

              if (a[sortBy[i]].deviation > b[sortBy[i]].deviation)
                return sortDesc[i] ? -1 : 1;
              if (a[sortBy[i]].deviation < b[sortBy[i]].deviation)
                return sortDesc[i] ? 1 : -1;
              continue;
            }
            if (hasPercent) {
              let aVal, bVal;
              if (a[sortBy[i]].percent === null || a[sortBy[i]].percent === "")
                aVal = 0;
              if (b[sortBy[i]].percent === null || b[sortBy[i]].percent === "")
                bVal = 0;
              if (aVal === 0 && bVal !== 0) return 1;
              if (bVal === 0 && aVal !== 0) return -1;

              if (a[sortBy[i]].percent > b[sortBy[i]].percent)
                return sortDesc[i] ? -1 : 1;
              if (a[sortBy[i]].percent < b[sortBy[i]].percent)
                return sortDesc[i] ? 1 : -1;
              continue;
            }
          } catch {}

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    handleShowDetail(data) {
      if (data.items && data.items.length > 0) {
        this.detailDialogModel = true;
        this.detailsData = data.items;
      }
    },
    async fetchData() {
      this.loadings.table = true;

      const payload = {
        filter: {
          project_id: this.$store.getters["project/active_project"],
          ...this.filters,
        },
      };

      const resp = await this.$store.dispatch(
        "contentball-analytics/fetchData",
        payload
      );

      if (resp) {
        this.items = resp.items;
        this.headers = resp.headers;
      }

      this.loadings.table = false;
    },
  },
};
</script>
<style lang="scss">
.contentball-analytics {
  &__table {
    tr.marked {
      background-color: rgba(196, 39, 39, 0.2);

      &:hover {
        background-color: rgba(196, 39, 39, 0.26) !important;
      }
    }
  }

  .v-data-table {
    table {
      th {
        &:nth-child(2),
        &:nth-child(22),
        &:nth-child(24),
        &:nth-child(26),
        &:nth-child(28) {
          border-right: 1px solid rgba(155, 155, 155, 0.3);
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(2),
            &:nth-child(22),
            &:nth-child(24),
            &:nth-child(26),
            &:nth-child(28) {
              border-right: 1px solid rgba(155, 155, 155, 0.3);
            }
          }
        }
      }
    }
  }
}

.activity-table {
  tr:nth-child(2) {
    font-weight: 600 !important;
  }
}
</style>
