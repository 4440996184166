<template>
  <td>
    <div :class="`colored-td${getColor} full-size`">
      <span>{{getValue}}</span>
    </div>
  </td>
</template>

<script>
export default {
  props: ['data','valName'],
  computed: {
    getColor() {
      const prevDayKey = 'd' + (Number(this.$moment().subtract(1, 'day').format('d')) + 1)
      if(this.valName === prevDayKey){
        return ' blue'
      }
      return ''
    },
    getValue() {
      let value = this.data[this.valName]
      if(value === '00:00') value = ''
      return value
    }
  }
}
</script>