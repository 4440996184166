export const MonthlyMovementByTaskBreadcrumbs = [
  {
    text: "Home",
    to: "/",
  },
  {
    text: "Dashboards",
    disabled: true,
  },
  {
    text: "Monthly movement by tasks",
    disabled: true,
  },
];

export const Endpoints = {
  GetMovementTasksData: "/worksection/report/monthly/movement-tasks",
};

export const StatusItemsData = [
  {
    text: "Done",
    value: 1,
  },
  {
    text: "In Work",
    value: 0,
  },
  {
    text: "TL is PlanResult",
    value: 2,
  },
  {
    text: "SEO is Plan Result",
    value: 3,
  },
  {
    text: "Not approved TL",
    value: 4,
  },
  {
    text: "Not approved SEO",
    value: 5,
  },
];

export const MOCK_M_DT_I = {
  Month_A: {
    name: "Month_A",
    headers: [
      {
        text: "Task",
        value: "name",
      },
      {
        text: "Metric",
        value: "metric",
      },
      {
        text: "Executive",
        value: "executive",
      },
      {
        text: "Total Time",
        value: "total_time",
      },
      {
        text: "Estimate",
        value: "max_time",
      },
      {
        text: "06-01",
        value: "d_0",
        weekend: true,
      },
      {
        text: "06-02",
        value: "d_1",
        weekend: true,
      },
      {
        text: "06-03",
        value: "d_2",
        weekend: false,
      },
      {
        text: "06-04",
        value: "d_3",
        weekend: false,
      },
      {
        text: "06-05",
        value: "d_4",
        weekend: false,
      },
      {
        text: "06-06",
        value: "d_5",
        weekend: false,
      },
      {
        text: "06-07",
        value: "d_6",
        weekend: false,
      },
      {
        text: "06-08",
        value: "d_7",
        weekend: true,
      },
      {
        text: "06-09",
        value: "d_8",
        weekend: true,
      },
      {
        text: "06-10",
        value: "d_9",
        weekend: false,
      },
      {
        text: "06-11",
        value: "d_10",
        weekend: false,
      },
      {
        text: "06-12",
        value: "d_11",
        weekend: false,
      },
      {
        text: "06-13",
        value: "d_12",
        weekend: false,
      },
      {
        text: "06-14",
        value: "d_13",
        weekend: false,
      },
      {
        text: "06-15",
        value: "d_14",
        weekend: true,
      },
      {
        text: "06-16",
        value: "d_15",
        weekend: true,
      },
      {
        text: "06-17",
        value: "d_16",
        weekend: false,
      },
      {
        text: "06-18",
        value: "d_17",
        weekend: false,
      },
      {
        text: "06-19",
        value: "d_18",
        weekend: false,
      },
      {
        text: "06-20",
        value: "d_19",
        weekend: false,
      },
      {
        text: "06-21",
        value: "d_20",
        weekend: false,
      },
      {
        text: "06-22",
        value: "d_21",
        weekend: true,
      },
      {
        text: "06-23",
        value: "d_22",
        weekend: true,
      },
      {
        text: "06-24",
        value: "d_23",
        weekend: false,
      },
      {
        text: "06-25",
        value: "d_24",
        weekend: false,
      },
      {
        text: "06-26",
        value: "d_25",
        weekend: false,
      },
      {
        text: "06-27",
        value: "d_26",
        weekend: false,
      },
      {
        text: "06-28",
        value: "d_27",
        weekend: false,
      },
      {
        text: "06-29",
        value: "d_28",
        weekend: true,
      },
      {
        text: "06-30",
        value: "d_29",
        weekend: true,
      },
      {
        text: "Team",
        value: "team",
      },
      {
        text: "Role",
        value: "role",
      },
    ],
    items: [
      {
        id: "12597415",
        name: "Create the Full New Disavov List by URLs & Domains",
        page: "/project/317783/12597415/",
        status: "active",
        priority: 115,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 16:33",
        date_end: "2024-06-28",
        max_time: 40,
        tags: {
          470850: "Links",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/317783/12597415/",
        child: [],
        metric: ["Links"],
        executive: "stanislav.yavtushenko@boosta.co",
        total_time: 4.7,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 1,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 3.7,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 4.7,
          other_months: 0,
        },
      },
      {
        id: "12586827",
        name: "Payment page: Make instructions for each content block",
        page: "/project/248054/12586827/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-29 18:07",
        date_end: "2024-06-19",
        max_time: 20,
        tags: {
          470849: "Content",
          640328: "Week_A",
          982253: "JUN",
          993395: "Hrystyna-Anton",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/248054/12586827/",
        child: [],
        metric: ["Content"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 6.1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 3.2,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0.6,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 1,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 1.3,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 6.7,
          other_months: 0.7,
        },
      },
      {
        id: "12596819",
        name: "Create 33 Content Briefs",
        page: "/project/317783/12596791/12596819/",
        status: "active",
        priority: 105,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413001",
          email: "mykola.yaremchuk@boosta.co",
          name: "Mykola Yaremchuk",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:22",
        date_end: "2024-06-17",
        max_time: 30,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Taikin",
        role: "TL SEO",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596819/",
        child: [],
        metric: ["Content"],
        executive: "mykola.yaremchuk@boosta.co",
        total_time: 35.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 6.1,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 5.5,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 3.5,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 4.5,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 6.4,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 5.5,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 2.3,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 1.2,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.2,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: true,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 35.3,
          other_months: 0,
        },
      },
      {
        id: "12590175",
        name: "Define casinos, GEOs and semantics for brand sites",
        page: "/project/316031/12590175/",
        status: "active",
        priority: 100,
        user_from: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        user_to: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        project: {
          id: "316031",
          name: "Geek Gamble",
          page: "/project/316031/",
        },
        date_added: "2024-05-31 11:49",
        date_end: "2024-06-14",
        tags: {
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/316031/12590175/",
        child: [],
        metric: [],
        executive: "illia.korchahin@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: true,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12596823",
        name: "Create 33 Content Briefs",
        page: "/project/317783/12596791/12596823/",
        status: "active",
        priority: 85,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "406706",
          email: "yelyzaveta.kudriavtseva@boosta.co",
          name: "Yelyzaveta Kudriavtseva",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:23",
        date_end: "2024-06-18",
        max_time: 30,
        tags: {
          470849: "Content",
          640328: "Week_A",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Jets",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596823/",
        child: [],
        metric: ["Content"],
        executive: "yelyzaveta.kudriavtseva@boosta.co",
        total_time: 6,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 6,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: true,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 6,
          other_months: 0,
        },
      },
      {
        id: "12596855",
        name: "Publish 50 Texts",
        page: "/project/317783/12596791/12596855/",
        status: "active",
        priority: 85,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "406706",
          email: "yelyzaveta.kudriavtseva@boosta.co",
          name: "Yelyzaveta Kudriavtseva",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:33",
        date_end: "2024-06-28",
        max_time: 25,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994647: "Jun 17-28",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Jets",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596855/",
        child: [],
        metric: ["Content"],
        executive: "yelyzaveta.kudriavtseva@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12590435",
        name: "Comprehensive 4DX-Based Plan for Goal Achievement, KPI Structuring, Dashboard Integration, and Review Protocols",
        page: "/project/223286/12590435/",
        status: "active",
        priority: 80,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-05-31 12:59",
        date_end: "2024-06-25",
        max_time: 60,
        tags: {
          982253: "JUN",
          994563: "Month_A",
        },
        team: "Management",
        role: "OM",
        task_url: "https://ra.worksection.com//project/223286/12590435/",
        child: [],
        metric: [],
        executive: "hanna.bilozerska@boosta.co",
        total_time: 7.5,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 5,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 2.5,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 7.5,
          other_months: 0,
        },
      },
      {
        id: "12596815",
        name: "Create 33 Content Briefs",
        page: "/project/317783/12596791/12596815/",
        status: "active",
        priority: 80,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413005",
          email: "oleksandr.biletskyi@boosta.co",
          name: "Oleksandr Biletskyy",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:21",
        date_end: "2024-06-21",
        max_time: 30,
        tags: {
          470849: "Content",
          640328: "Week_A",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Taikin",
        role: "SEO",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596815/",
        child: [],
        metric: ["Content"],
        executive: "oleksandr.biletskyi@boosta.co",
        total_time: 20.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 6,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 3,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 6.6,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 3.7,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 1,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 20.3,
          other_months: 0,
        },
      },
      {
        id: "12596851",
        name: "Publish 50 Texts",
        page: "/project/317783/12596791/12596851/",
        status: "active",
        priority: 75,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413719",
          email: "sofiia.filima@boosta.co",
          name: "Sofiia Filima",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:32",
        date_end: "2024-06-28",
        max_time: 25,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994647: "Jun 17-28",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Jets",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596851/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.filima@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12594955",
        name: "Встановити правила планування та виконання манслі й спринтів",
        page: "/project/223286/12594955/",
        status: "active",
        priority: 70,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-06-03 17:19",
        date_end: "2024-06-28",
        max_time: 30,
        tags: {
          382401: "Strategic",
          641411: "People #",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        team: "Management",
        role: "TL LB",
        task_url: "https://ra.worksection.com//project/223286/12594955/",
        child: [],
        metric: ["Strategic"],
        executive: "vadym.lavrenchuk@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12609923",
        name: "Визначити стандарти постановки задач",
        page: "/project/223286/12609923/",
        status: "active",
        priority: 70,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-06-11 19:41",
        date_end: "2024-06-18",
        tags: {
          382401: "Strategic",
          954224: "B",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL LB",
        task_url: "https://ra.worksection.com//project/223286/12609923/",
        child: [],
        metric: ["Strategic"],
        executive: "vadym.lavrenchuk@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: true,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12604975",
        name: "Develop Quality Criteria and Checklist for Top Casino List Table",
        page: "/project/317007/12604975/",
        status: "active",
        priority: 70,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-10 09:45",
        date_end: "2024-06-28",
        max_time: 8,
        tags: {
          470852: "Conversion",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        team: "Management",
        role: "Account manager",
        task_url: "https://ra.worksection.com//project/317007/12604975/",
        child: [],
        metric: ["Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 1.2,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0.5,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0.5,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.2,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 1.2,
          other_months: 0,
        },
      },
      {
        id: "12507183",
        name: "Make audit of CZ region in Casino niche",
        page: "/project/297656/12507183/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "297656",
          name: "[ki] Kasinoceske.com",
          page: "/project/297656/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-29",
        max_time: 50,
        tags: {
          382401: "Strategic",
          640328: "Week_A",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994487: "May 29-17",
          994491: "May 20-31",
          994563: "Month_A",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/297656/12507183/",
        child: [],
        metric: ["Strategic"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: true,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.6,
          other_months: 0.6,
        },
      },
      {
        id: "12596911",
        name: "Write 15 Texts",
        page: "/project/317783/12596791/12596911/",
        status: "active",
        priority: 50,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "414083",
          email: "anastasiia.malaman@boosta.co",
          name: "Anastasiia Malaman",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:46",
        date_end: "2024-06-27",
        max_time: 100,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Jets",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12596911/",
        child: [],
        metric: ["Content"],
        executive: "anastasiia.malaman@boosta.co",
        total_time: 36.6,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 7.8,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 6.8,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 7.2,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 7,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 7.8,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 36.6,
          other_months: 0,
        },
      },
      {
        id: "12477463",
        name: "Implement external user comments",
        page: "/project/316031/12477463/",
        status: "done",
        priority: 50,
        user_from: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        user_to: {
          id: "413375",
          email: "rostyslav.yakuts@boosta.co",
          name: "Rostyslav Yakuts",
        },
        project: {
          id: "316031",
          name: "Geek Gamble",
          page: "/project/316031/",
        },
        date_added: "2024-04-05 11:29",
        date_end: "2024-06-14",
        date_closed: "2024-06-15 10:43",
        tags: {
          982253: "JUN",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        team: "Programmers",
        role: "SOFT",
        task_url: "https://ra.worksection.com//project/316031/12477463/",
        child: [],
        metric: [],
        executive: "rostyslav.yakuts@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: true,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 5.6,
          other_months: 5.6,
        },
      },
      {
        id: "12518639",
        name: "Collect & Upload to the System the Semantics data for CA pages",
        page: "/project/317783/12401379/12518639/",
        status: "done",
        priority: 45,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "407386",
          email: "valentyna.mylko@boosta.co",
          name: "Valentyna Mylko",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-04-24 15:40",
        date_end: "2024-06-14",
        date_closed: "2024-06-14 15:41",
        tags: {
          382401: "Strategic",
          640328: "Week_A",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994487: "May 29-17",
          994563: "Month_A",
          994643: "Jun 3-14",
        },
        parent: {
          id: "12401379",
          name: "Collect & Upload to the System the Semantics data for all the pages",
          page: "/project/317783/12401379/",
          status: "active",
          priority: "10",
        },
        team: "Jets",
        role: "SEO",
        task_url:
          "https://ra.worksection.com//project/317783/12401379/12518639/",
        child: [],
        metric: ["Strategic"],
        executive: "valentyna.mylko@boosta.co",
        total_time: 49.7,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 3.7,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 5,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 4.2,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 5.3,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 5.3,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 5.5,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 5.2,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 5,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 5.1,
        _d_13: {
          weekend: false,
          deadline: true,
        },
        d_13: 5.4,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 106,
          other_months: 56.3,
        },
      },
      {
        id: "12205711",
        name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
        page: "/project/241154/12205711/",
        status: "active",
        priority: 40,
        user_from: {
          id: "180088",
          email: "anton.reva@boosta.co",
          name: "Anton Reva",
        },
        user_to: {
          id: "408171",
          email: "yevhenii.slabko@boosta.co",
          name: "Yevhenii Slabko",
        },
        project: {
          id: "241154",
          name: "LB Managment",
          page: "/project/241154/",
        },
        date_added: "2023-12-18 15:44",
        date_end: "2024-06-18",
        time_end: "18:00",
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        team: "Management",
        role: "TL LB Outreach",
        task_url: "https://ra.worksection.com//project/241154/12205711/",
        child: [
          {
            id: "12209379",
            name: "Надати дані згідно аналізу для подальшого ресерчу",
            page: "/project/241154/12205711/12209379/",
            status: "done",
            priority: "10",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "350730",
              email: "hrystyna.ihnatova@boosta.co",
              name: "Kristina Ihnatova",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2023-12-19 16:35",
            date_end: "2024-01-11",
            date_closed: "2024-01-12 23:03",
            time_end: "18:00",
            tags: {
              382401: "Strategic",
              470850: "Links",
              640325: "Week_C",
              994091: "Jan 2-19",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
          {
            id: "12209383",
            name: "Надати дані згідно аналізу для подальшого ресерчу",
            page: "/project/241154/12205711/12209383/",
            status: "done",
            priority: "9",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "361139",
              email: "stanislav.yavtushenko@boosta.co",
              name: "Stanislav Yavtushenko",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2023-12-19 16:35",
            date_end: "2024-01-05",
            date_closed: "2024-01-05 14:36",
            time_end: "18:00",
            tags: {
              382401: "Strategic",
              470850: "Links",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
          {
            id: "12209387",
            name: "Надати дані згідно аналізу для подальшого ресерчу",
            page: "/project/241154/12205711/12209387/",
            status: "done",
            priority: "9",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "413001",
              email: "mykola.yaremchuk@boosta.co",
              name: "Mykola Yaremchuk",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2023-12-19 16:36",
            date_end: "2023-12-20",
            date_closed: "2023-12-22 11:28",
            time_end: "18:00",
            max_time: 0.30000000000000004,
            tags: {
              382401: "Strategic",
              470850: "Links",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
          {
            id: "12209391",
            name: "Надати дані згідно аналізу для подальшого ресерчу",
            page: "/project/241154/12205711/12209391/",
            status: "done",
            priority: "9",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "363015",
              email: "oleksandr.rybachuk@boosta.co",
              name: "Oleksandr Rybachuk",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2023-12-19 16:36",
            date_end: "2023-12-27",
            date_closed: "2023-12-27 12:02",
            time_end: "18:00",
            tags: {
              382401: "Strategic",
              470850: "Links",
              954224: "B",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
          {
            id: "12209395",
            name: "Надати дані згідно аналізу для подальшого ресерчу",
            page: "/project/241154/12205711/12209395/",
            status: "done",
            priority: "9",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "407386",
              email: "valentyna.mylko@boosta.co",
              name: "Valentyna Mylko",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2023-12-19 16:37",
            date_end: "2024-01-05",
            date_closed: "2024-01-05 14:40",
            time_end: "18:00",
            tags: {
              382401: "Strategic",
              470850: "Links",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
          {
            id: "12265431",
            name: "Подивитися та дати фідбек по множинам",
            page: "/project/241154/12205711/12265431/",
            status: "done",
            priority: "10",
            user_from: {
              id: "360404",
              email: "vadym.lavrenchuk@boosta.co",
              name: "Vadym Lavrenchuk",
            },
            user_to: {
              id: "180088",
              email: "anton.reva@boosta.co",
              name: "Anton Reva",
            },
            project: {
              id: "241154",
              name: "LB Managment",
              page: "/project/241154/",
            },
            date_added: "2024-01-16 10:18",
            date_end: "2024-01-16",
            date_closed: "2024-01-17 14:02",
            tags: {
              954221: "A",
            },
            parent: {
              id: "12205711",
              name: "Niche Analyses: collect need link clusters => get lead by all each clusters => create process",
              page: "/project/241154/12205711/",
              status: "active",
              priority: "9",
            },
          },
        ],
        metric: ["Strategic"],
        executive: "yevhenii.slabko@boosta.co",
        total_time: 1.2,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0.8,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.5,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: true,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 14.8,
          other_months: 13.6,
        },
      },
      {
        id: "12595447",
        name: "Project 200: make research and get 20 backlinks",
        page: "/project/248054/12595447/",
        status: "active",
        priority: 5,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "407471",
          email: "anna.kulieshyna@boosta.co",
          name: "Anna Kulieshyna",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-06-03 21:47",
        date_end: "2024-06-27",
        max_time: 10,
        tags: {
          470850: "Links",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "LB Crowd",
        role: "LB Crowd",
        task_url: "https://ra.worksection.com//project/248054/12595447/",
        child: [],
        metric: ["Links"],
        executive: "anna.kulieshyna@boosta.co",
        total_time: 14.2,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 2.4,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 2.2,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 1.9,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 1.5,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 1.3,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 1.5,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 1.6,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 1.6,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 14.2,
          other_months: 0,
        },
      },
      {
        id: "12596791",
        name: "Order, Review and Publish 100 New Pages",
        page: "/project/317783/12596791/",
        status: "active",
        priority: 1,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "414551",
          email: "serhii.zdrok@boosta.co",
          name: "Serhii Zdrok",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 13:14",
        date_end: "2024-06-28",
        max_time: 80,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/317783/12596791/",
        child: [],
        metric: ["Content"],
        executive: "serhii.zdrok@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12606583",
        name: "Write 15 Texts",
        page: "/project/317783/12596791/12606583/",
        status: "active",
        priority: 1,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413158",
          email: "anastasiia.prokopenko@boosta.co",
          name: "Anastasiia Prokopenko",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-10 15:09",
        date_end: "2024-06-27",
        max_time: 100,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "Geeks",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12606583/",
        child: [],
        metric: ["Content"],
        executive: "anastasiia.prokopenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507155",
        name: "Make audit of AU region in Casino niche",
        page: "/project/248054/12507155/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-25",
        max_time: 40,
        tags: {
          382401: "Strategic",
          640328: "Week_A",
          982247: "APR",
          982250: "MAY",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994395: "Apr 15-26",
          994487: "May 29-17",
          994491: "May 20-31",
          994563: "Month_A",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507155/",
        child: [],
        metric: ["Strategic"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 1.5,
          other_months: 1.5,
        },
      },
      {
        id: "12554479",
        name: "Write 12 new texts according to text briefs",
        page: "/project/248054/12554479/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415251",
          email: "anna.voitovych@boosta.co",
          name: "Anna Voitovych",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-13 17:51",
        date_end: "2024-06-27",
        max_time: 96,
        tags: {
          470849: "Content",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994563: "Month_A",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12554479/",
        child: [],
        metric: ["Content"],
        executive: "anna.voitovych@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12606591",
        name: "Write 15 Texts",
        page: "/project/317783/12596791/12606591/",
        status: "active",
        priority: 0,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "415123",
          email: "tetiana.zelenko@boosta.co",
          name: "Tetiana Zelenko",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-10 15:10",
        date_end: "2024-06-27",
        max_time: 100,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994563: "Month_A",
        },
        parent: {
          id: "12596791",
          name: "Order, Review and Publish 100 New Pages",
          page: "/project/317783/12596791/",
          status: "active",
          priority: "9",
        },
        team: "",
        role: "",
        task_url:
          "https://ra.worksection.com//project/317783/12596791/12606591/",
        child: [],
        metric: ["Content"],
        executive: "tetiana.zelenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
    ],
  },
  Month_B: {
    name: "Month_B",
    headers: [
      {
        text: "Task",
        value: "name",
      },
      {
        text: "Metric",
        value: "metric",
      },
      {
        text: "Executive",
        value: "executive",
      },
      {
        text: "Total Time",
        value: "total_time",
      },
      {
        text: "Estimate",
        value: "max_time",
      },
      {
        text: "06-01",
        value: "d_0",
        weekend: true,
      },
      {
        text: "06-02",
        value: "d_1",
        weekend: true,
      },
      {
        text: "06-03",
        value: "d_2",
        weekend: false,
      },
      {
        text: "06-04",
        value: "d_3",
        weekend: false,
      },
      {
        text: "06-05",
        value: "d_4",
        weekend: false,
      },
      {
        text: "06-06",
        value: "d_5",
        weekend: false,
      },
      {
        text: "06-07",
        value: "d_6",
        weekend: false,
      },
      {
        text: "06-08",
        value: "d_7",
        weekend: true,
      },
      {
        text: "06-09",
        value: "d_8",
        weekend: true,
      },
      {
        text: "06-10",
        value: "d_9",
        weekend: false,
      },
      {
        text: "06-11",
        value: "d_10",
        weekend: false,
      },
      {
        text: "06-12",
        value: "d_11",
        weekend: false,
      },
      {
        text: "06-13",
        value: "d_12",
        weekend: false,
      },
      {
        text: "06-14",
        value: "d_13",
        weekend: false,
      },
      {
        text: "06-15",
        value: "d_14",
        weekend: true,
      },
      {
        text: "06-16",
        value: "d_15",
        weekend: true,
      },
      {
        text: "06-17",
        value: "d_16",
        weekend: false,
      },
      {
        text: "06-18",
        value: "d_17",
        weekend: false,
      },
      {
        text: "06-19",
        value: "d_18",
        weekend: false,
      },
      {
        text: "06-20",
        value: "d_19",
        weekend: false,
      },
      {
        text: "06-21",
        value: "d_20",
        weekend: false,
      },
      {
        text: "06-22",
        value: "d_21",
        weekend: true,
      },
      {
        text: "06-23",
        value: "d_22",
        weekend: true,
      },
      {
        text: "06-24",
        value: "d_23",
        weekend: false,
      },
      {
        text: "06-25",
        value: "d_24",
        weekend: false,
      },
      {
        text: "06-26",
        value: "d_25",
        weekend: false,
      },
      {
        text: "06-27",
        value: "d_26",
        weekend: false,
      },
      {
        text: "06-28",
        value: "d_27",
        weekend: false,
      },
      {
        text: "06-29",
        value: "d_28",
        weekend: true,
      },
      {
        text: "06-30",
        value: "d_29",
        weekend: true,
      },
      {
        text: "Team",
        value: "team",
      },
      {
        text: "Role",
        value: "role",
      },
    ],
    items: [
      {
        id: "12607199",
        name: "Create strategy & budget for the team",
        page: "/project/244487/12607199/",
        status: "active",
        priority: 115,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-06-10 18:06",
        date_end: "2024-06-25",
        max_time: 10,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/244487/12607199/",
        child: [],
        metric: ["Strategic"],
        executive: "stanislav.yavtushenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597399",
        name: "Create Templates for 7 Page Types",
        page: "/project/317783/12597399/",
        status: "active",
        priority: 115,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 16:27",
        date_end: "2024-06-21",
        max_time: 20,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/317783/12597399/",
        child: [],
        metric: ["Content"],
        executive: "stanislav.yavtushenko@boosta.co",
        total_time: 9,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 3,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 5.5,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0.5,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 9,
          other_months: 0,
        },
      },
      {
        id: "12595739",
        name: "Find new regions to create new websites",
        page: "/project/244487/12595739/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-06-04 00:00",
        date_end: "2024-06-25",
        max_time: 3,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993971: "EXisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/244487/12595739/",
        child: [],
        metric: ["Strategic"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 0.9,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0.9,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.9,
          other_months: 0,
        },
      },
      {
        id: "12595743",
        name: "Create strategy & budget for the team",
        page: "/project/244487/12595743/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-06-04 00:02",
        date_end: "2024-06-25",
        max_time: 10,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/244487/12595743/",
        child: [],
        metric: ["Strategic"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 0.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0.3,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.3,
          other_months: 0,
        },
      },
      {
        id: "12342151",
        name: "Завершити налаштування процесу створення якісного контенту",
        page: "/project/244487/12342151/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-02-09 21:10",
        date_end: "2024-06-19",
        max_time: 6,
        tags: {
          382401: "Strategic",
          470849: "Content",
          640322: "Week_B",
          956639: "FEB",
          956642: "MAR",
          982247: "APR",
          982253: "JUN",
          990961: "Weekly MS",
          993971: "EXisPlanResult",
          994187: "Feb 5-16",
          994191: "Feb 19-1",
          994275: "Mar 4-15",
          994279: "Mar 18-29",
          994391: "Apr 1-12",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/244487/12342151/",
        child: [],
        metric: ["Content", "Strategic"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 18.1,
          other_months: 18.1,
        },
      },
      {
        id: "12368531",
        name: "Затвердити стратегію просування пріоритетних сторінок на основі аналізу конкурентів",
        page: "/project/248054/12368531/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-02-21 15:05",
        date_end: "2024-06-19",
        max_time: 3,
        tags: {
          382401: "Strategic",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/248054/12368531/",
        child: [],
        metric: ["Strategic"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 4.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 1.1,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 3.2,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 5,
          other_months: 0.7,
        },
      },
      {
        id: "12560259",
        name: "Analyze the backlink strategy for slots pages in AU",
        page: "/project/248054/12560259/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-15 16:11",
        date_end: "2024-06-19",
        max_time: 30,
        tags: {
          470850: "Links",
          640322: "Week_B",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994491: "May 20-31",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/248054/12560259/",
        child: [],
        metric: ["Links"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 1.2,
          other_months: 1.2,
        },
      },
      {
        id: "12597023",
        name: "Collect Sematic, Create ST Project and Categorize for 10 GEOs",
        page: "/project/317783/12597023/",
        status: "active",
        priority: 105,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413001",
          email: "mykola.yaremchuk@boosta.co",
          name: "Mykola Yaremchuk",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 14:24",
        date_end: "2024-06-28",
        max_time: 40,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "Taikin",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/317783/12597023/",
        child: [],
        metric: ["Strategic"],
        executive: "mykola.yaremchuk@boosta.co",
        total_time: 17.8,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 1.9,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 2.6,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0.4,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 4.2,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 3.9,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 4.8,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 17.8,
          other_months: 0,
        },
      },
      {
        id: "12607235",
        name: "Create strategy & budget for the team",
        page: "/project/244487/12607235/",
        status: "active",
        priority: 100,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-06-10 18:13",
        date_end: "2024-06-25",
        max_time: 10,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/244487/12607235/",
        child: [],
        metric: ["Strategic"],
        executive: "illia.korchahin@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12349107",
        name: "Подготовить ссылочную стратегию",
        page: "/project/316031/12349107/",
        status: "active",
        priority: 100,
        user_from: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        user_to: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        project: {
          id: "316031",
          name: "Geek Gamble",
          page: "/project/316031/",
        },
        date_added: "2024-02-13 15:50",
        date_end: "2024-06-28",
        tags: {
          382401: "Strategic",
          470850: "Links",
          640328: "Week_A",
          982250: "MAY",
          982253: "JUN",
          994491: "May 20-31",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/316031/12349107/",
        child: [],
        metric: ["Strategic", "Links"],
        executive: "illia.korchahin@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 5.5,
          other_months: 5.5,
        },
      },
      {
        id: "12604895",
        name: "To Set Full and Clear Process for TOP 5 Conversion pages Optimization",
        page: "/project/223286/12604895/",
        status: "active",
        priority: 80,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-06-10 09:26",
        date_end: "2024-07-15",
        max_time: 25,
        tags: {
          382401: "Strategic",
          470852: "Conversion",
          982253: "JUN",
          993199: "MProcess",
          994567: "Month_B",
        },
        team: "Management",
        role: "OM",
        task_url: "https://ra.worksection.com//project/223286/12604895/",
        child: [],
        metric: ["Strategic", "Conversion"],
        executive: "hanna.bilozerska@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12518939",
        name: "Collect & Upload to the System the Semantics data for NZ pages",
        page: "/project/317783/12401379/12518939/",
        status: "active",
        priority: 80,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413005",
          email: "oleksandr.biletskyi@boosta.co",
          name: "Oleksandr Biletskyy",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-04-24 17:05",
        date_end: "2024-07-19",
        max_time: 40,
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        parent: {
          id: "12401379",
          name: "Collect & Upload to the System the Semantics data for all the pages",
          page: "/project/317783/12401379/",
          status: "active",
          priority: "10",
        },
        team: "Taikin",
        role: "SEO",
        task_url:
          "https://ra.worksection.com//project/317783/12401379/12518939/",
        child: [],
        metric: ["Strategic"],
        executive: "oleksandr.biletskyi@boosta.co",
        total_time: 21.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 1,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 2.8,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 6,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 5.8,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 5.6,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 21.3,
          other_months: 0,
        },
      },
      {
        id: "12596731",
        name: "Low Impression Pages Audit",
        page: "/project/317783/12596731/",
        status: "active",
        priority: 80,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413005",
          email: "oleksandr.biletskyi@boosta.co",
          name: "Oleksandr Biletskyy",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-06-04 12:57",
        date_end: "2024-06-28",
        max_time: 24,
        tags: {
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Taikin",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/317783/12596731/",
        child: [],
        metric: [],
        executive: "oleksandr.biletskyi@boosta.co",
        total_time: 7.1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 1.3,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 2.5,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 3.3,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 7.1,
          other_months: 0,
        },
      },
      {
        id: "12594751",
        name: "Організувати процес підвищення кваліфікації команди - через навчальні заходи",
        page: "/project/223286/12594751/",
        status: "active",
        priority: 70,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-06-03 16:22",
        date_end: "2024-06-30",
        max_time: 25,
        tags: {
          382401: "Strategic",
          641411: "People #",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL LB",
        task_url: "https://ra.worksection.com//project/223286/12594751/",
        child: [],
        metric: ["Strategic"],
        executive: "vadym.lavrenchuk@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: true,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12594847",
        name: "Витік Google: розповсюджено інформацію та враховано у подальшій роботі",
        page: "/project/223286/12594847/",
        status: "active",
        priority: 70,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-06-03 16:52",
        date_end: "2024-06-26",
        max_time: 6,
        tags: {
          382401: "Strategic",
          641407: "Money $",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL LB",
        task_url: "https://ra.worksection.com//project/223286/12594847/",
        child: [],
        metric: ["Strategic"],
        executive: "vadym.lavrenchuk@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: true,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12595923",
        name: "Preparation and Implementation of Promotional Elements",
        page: "/project/317007/12595923/",
        status: "active",
        priority: 70,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-04 08:42",
        date_end: "2024-06-14",
        max_time: 30,
        tags: {
          382401: "Strategic",
          470852: "Conversion",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "Account manager",
        task_url: "https://ra.worksection.com//project/317007/12595923/",
        child: [],
        metric: ["Strategic", "Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: true,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12596299",
        name: "To compile a list of top-performing online casino offers suitable for placement on our websites targeting Poland and Czech Republic",
        page: "/project/317007/12596299/",
        status: "active",
        priority: 70,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-04 11:01",
        date_end: "2024-06-20",
        max_time: 10,
        tags: {
          382401: "Strategic",
          470852: "Conversion",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "Account manager",
        task_url: "https://ra.worksection.com//project/317007/12596299/",
        child: [],
        metric: ["Strategic", "Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 2.4,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0.3,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0.6,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 1.5,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: true,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 2.4,
          other_months: 0,
        },
      },
      {
        id: "12605475",
        name: "Re-check main table on TOP 5 with clicks",
        page: "/project/317007/12605475/",
        status: "active",
        priority: 70,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-10 10:57",
        date_end: "2024-07-12",
        tags: {
          470852: "Conversion",
          982253: "JUN",
          983978: "JUL",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "Account manager",
        task_url: "https://ra.worksection.com//project/317007/12605475/",
        child: [],
        metric: ["Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12368535",
        name: "Create list of pages to write",
        page: "/project/297656/12368535/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "297656",
          name: "[ki] Kasinoceske.com",
          page: "/project/297656/",
        },
        date_added: "2024-02-21 15:05",
        date_end: "2024-06-17",
        max_time: 5,
        tags: {
          382401: "Strategic",
          640322: "Week_B",
          641407: "Money $",
          954224: "B",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/297656/12368535/",
        child: [],
        metric: ["Strategic"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0.5,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.5,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: true,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.5,
          other_months: 0,
        },
      },
      {
        id: "12530215",
        name: "Create 8 new pages",
        page: "/project/248054/12530215/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-30 13:55",
        date_end: "2024-06-28",
        max_time: 22,
        tags: {
          470849: "Content",
          640325: "Week_C",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248054/12530215/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12589403",
        name: "Order & publish 11 new bonus pages",
        page: "/project/248054/12589403/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-30 20:15",
        date_end: "2024-06-21",
        max_time: 12,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248054/12589403/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597699",
        name: "Find donors for placement among used",
        page: "/project/248054/12597699/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-06-04 18:37",
        date_end: "2024-06-17",
        max_time: 2.5,
        tags: {
          470850: "Links",
          491736: "Docs",
          640322: "Week_B",
          954224: "B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248054/12597699/",
        child: [],
        metric: ["Links"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: true,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597703",
        name: "Analyze the dynamics of positions for https://aussiecasinoreviewer.com/5-deposit-casino/",
        page: "/project/248054/12597703/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-06-04 18:45",
        date_end: "2024-06-17",
        max_time: 3,
        tags: {
          470849: "Content",
          470850: "Links",
          491736: "Docs",
          640322: "Week_B",
          954224: "B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248054/12597703/",
        child: [],
        metric: ["Content", "Links"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 2.1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 2.1,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: true,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 2.1,
          other_months: 0,
        },
      },
      {
        id: "12618631",
        name: "Create 12 briefs for test tasks according to existing structure",
        page: "/project/248090/12618631/",
        status: "active",
        priority: 69,
        user_from: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248090",
          name: "[ki] Leafletcasino.com",
          page: "/project/248090/",
        },
        date_added: "2024-06-17 11:36",
        date_end: "2024-06-28",
        max_time: 12,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248090/12618631/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12587615",
        name: "Strategic Movement by Jets: Priority Pages and Need Top Pages | Canada",
        page: "/project/247434/12587615/",
        status: "active",
        priority: 45,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "407386",
          email: "valentyna.mylko@boosta.co",
          name: "Valentyna Mylko",
        },
        project: {
          id: "247434",
          name: "[SY] TestCasinos.com: Canada - EN",
          page: "/project/247434/",
        },
        date_added: "2024-05-30 10:55",
        date_end: "2024-06-21",
        tags: {
          382401: "Strategic",
          640328: "Week_A",
          641407: "Money $",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
          994647: "Jun 17-28",
        },
        team: "Jets",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/247434/12587615/",
        child: [],
        metric: ["Strategic"],
        executive: "valentyna.mylko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597819",
        name: "Розробити систему бонусів для команди",
        page: "/project/241154/12597819/",
        status: "active",
        priority: 40,
        user_from: {
          id: "408171",
          email: "yevhenii.slabko@boosta.co",
          name: "Yevhenii Slabko",
        },
        user_to: {
          id: "408171",
          email: "yevhenii.slabko@boosta.co",
          name: "Yevhenii Slabko",
        },
        project: {
          id: "241154",
          name: "LB Managment",
          page: "/project/241154/",
        },
        date_added: "2024-06-04 21:45",
        date_end: "2024-06-28",
        tags: {
          382401: "Strategic",
          982253: "JUN",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL LB Outreach",
        task_url: "https://ra.worksection.com//project/241154/12597819/",
        child: [],
        metric: ["Strategic"],
        executive: "yevhenii.slabko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12559995",
        name: "Зібрати усі усі mainpages",
        page: "/project/241154/12559995/",
        status: "active",
        priority: 40,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "408171",
          email: "yevhenii.slabko@boosta.co",
          name: "Yevhenii Slabko",
        },
        project: {
          id: "241154",
          name: "LB Managment",
          page: "/project/241154/",
        },
        date_added: "2024-05-15 15:12",
        date_end: "2024-06-28",
        tags: {
          981338: "LB Overview",
          982250: "MAY",
          982253: "JUN",
          994567: "Month_B",
        },
        team: "Management",
        role: "TL LB Outreach",
        task_url: "https://ra.worksection.com//project/241154/12559995/",
        child: [],
        metric: [],
        executive: "yevhenii.slabko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.3,
          other_months: 0.3,
        },
      },
      {
        id: "12601311",
        name: "Make deep research for 4 donors with problem placement",
        page: "/project/248090/12601311/",
        status: "active",
        priority: 5,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "407471",
          email: "anna.kulieshyna@boosta.co",
          name: "Anna Kulieshyna",
        },
        project: {
          id: "248090",
          name: "[ki] Leafletcasino.com",
          page: "/project/248090/",
        },
        date_added: "2024-06-06 13:23",
        date_end: "2024-06-21",
        max_time: 10,
        tags: {
          470850: "Links",
          491736: "Docs",
          640322: "Week_B",
          982253: "JUN",
          994567: "Month_B",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "LB Crowd",
        role: "LB Crowd",
        task_url: "https://ra.worksection.com//project/248090/12601311/",
        child: [],
        metric: ["Links"],
        executive: "anna.kulieshyna@boosta.co",
        total_time: 3.7,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0.9,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 1.1,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0.8,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.8,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 3.7,
          other_months: 0,
        },
      },
      {
        id: "12596835",
        name: "Write [5] Texts",
        page: "/project/316031/12596835/",
        status: "active",
        priority: 1,
        user_from: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        user_to: {
          id: "413158",
          email: "anastasiia.prokopenko@boosta.co",
          name: "Anastasiia Prokopenko",
        },
        project: {
          id: "316031",
          name: "Geek Gamble",
          page: "/project/316031/",
        },
        date_added: "2024-06-04 13:28",
        date_end: "2024-06-28",
        tags: {
          640328: "Week_A",
          982253: "JUN",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "Geeks",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/316031/12596835/",
        child: [],
        metric: [],
        executive: "anastasiia.prokopenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507187",
        name: "Make audit of PL region in Casino niche & create strategy for new site creation",
        page: "/project/244487/12507187/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "244487",
          name: "GM [common]",
          page: "/project/244487/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-21",
        max_time: 40,
        tags: {
          382401: "Strategic",
          640322: "Week_B",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/244487/12507187/",
        child: [],
        metric: ["Strategic"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0.1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0.1,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.1,
          other_months: 0,
        },
      },
      {
        id: "12507167",
        name: "Analyze the backlink strategy for bonus pages in AU",
        page: "/project/248054/12507167/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-25",
        max_time: 30,
        tags: {
          470850: "Links",
          640322: "Week_B",
          982250: "MAY",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994487: "May 29-17",
          994491: "May 20-31",
          994567: "Month_B",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507167/",
        child: [],
        metric: ["Links"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 1,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 1,
          other_months: 0,
        },
      },
      {
        id: "12507175",
        name: "Analyze the budget of guest posts of top competitors",
        page: "/project/248054/12507175/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-21",
        max_time: 3,
        tags: {
          470850: "Links",
          640322: "Week_B",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507175/",
        child: [],
        metric: ["Links"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507195",
        name: "Manage and review the creation of 30 pages",
        page: "/project/248054/12507195/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-28",
        max_time: 30,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994567: "Month_B",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507195/",
        child: [],
        metric: ["Content"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12508503",
        name: "Write 5 new texts according to text briefs",
        page: "/project/248054/12508503/",
        status: "done",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415251",
          email: "anna.voitovych@boosta.co",
          name: "Anna Voitovych",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 21:18",
        date_end: "2024-06-14",
        date_closed: "2024-06-17 09:29",
        max_time: 40,
        tags: {
          470849: "Content",
          640328: "Week_A",
          982250: "MAY",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994491: "May 20-31",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12508503/",
        child: [],
        metric: ["Content"],
        executive: "anna.voitovych@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: true,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12596839",
        name: "Write [5] Texts",
        page: "/project/316031/12596839/",
        status: "active",
        priority: 0,
        user_from: {
          id: "412293",
          email: "illia.korchahin@boosta.co",
          name: "Illia Korhchain",
        },
        user_to: {
          id: "415123",
          email: "tetiana.zelenko@boosta.co",
          name: "Tetiana Zelenko",
        },
        project: {
          id: "316031",
          name: "Geek Gamble",
          page: "/project/316031/",
        },
        date_added: "2024-06-04 13:28",
        date_end: "2024-06-28",
        tags: {
          640328: "Week_A",
          982253: "JUN",
          994567: "Month_B",
          994643: "Jun 3-14",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/316031/12596839/",
        child: [],
        metric: [],
        executive: "tetiana.zelenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
    ],
  },
  Month_C: {
    name: "Month_C",
    headers: [
      {
        text: "Task",
        value: "name",
      },
      {
        text: "Metric",
        value: "metric",
      },
      {
        text: "Executive",
        value: "executive",
      },
      {
        text: "Total Time",
        value: "total_time",
      },
      {
        text: "Estimate",
        value: "max_time",
      },
      {
        text: "06-01",
        value: "d_0",
        weekend: true,
      },
      {
        text: "06-02",
        value: "d_1",
        weekend: true,
      },
      {
        text: "06-03",
        value: "d_2",
        weekend: false,
      },
      {
        text: "06-04",
        value: "d_3",
        weekend: false,
      },
      {
        text: "06-05",
        value: "d_4",
        weekend: false,
      },
      {
        text: "06-06",
        value: "d_5",
        weekend: false,
      },
      {
        text: "06-07",
        value: "d_6",
        weekend: false,
      },
      {
        text: "06-08",
        value: "d_7",
        weekend: true,
      },
      {
        text: "06-09",
        value: "d_8",
        weekend: true,
      },
      {
        text: "06-10",
        value: "d_9",
        weekend: false,
      },
      {
        text: "06-11",
        value: "d_10",
        weekend: false,
      },
      {
        text: "06-12",
        value: "d_11",
        weekend: false,
      },
      {
        text: "06-13",
        value: "d_12",
        weekend: false,
      },
      {
        text: "06-14",
        value: "d_13",
        weekend: false,
      },
      {
        text: "06-15",
        value: "d_14",
        weekend: true,
      },
      {
        text: "06-16",
        value: "d_15",
        weekend: true,
      },
      {
        text: "06-17",
        value: "d_16",
        weekend: false,
      },
      {
        text: "06-18",
        value: "d_17",
        weekend: false,
      },
      {
        text: "06-19",
        value: "d_18",
        weekend: false,
      },
      {
        text: "06-20",
        value: "d_19",
        weekend: false,
      },
      {
        text: "06-21",
        value: "d_20",
        weekend: false,
      },
      {
        text: "06-22",
        value: "d_21",
        weekend: true,
      },
      {
        text: "06-23",
        value: "d_22",
        weekend: true,
      },
      {
        text: "06-24",
        value: "d_23",
        weekend: false,
      },
      {
        text: "06-25",
        value: "d_24",
        weekend: false,
      },
      {
        text: "06-26",
        value: "d_25",
        weekend: false,
      },
      {
        text: "06-27",
        value: "d_26",
        weekend: false,
      },
      {
        text: "06-28",
        value: "d_27",
        weekend: false,
      },
      {
        text: "06-29",
        value: "d_28",
        weekend: true,
      },
      {
        text: "06-30",
        value: "d_29",
        weekend: true,
      },
      {
        text: "Team",
        value: "team",
      },
      {
        text: "Role",
        value: "role",
      },
    ],
    items: [
      {
        id: "12118039",
        name: "Implement the functionality of a multi-regional table",
        page: "/project/318175/12118039/",
        status: "active",
        priority: 115,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        project: {
          id: "318175",
          name: "Atomic Project",
          page: "/project/318175/",
        },
        date_added: "2023-11-14 17:46",
        date_end: "2024-06-28",
        max_time: 5,
        tags: {
          470853: "Technical",
          956636: "JAN",
          956639: "FEB",
          956642: "MAR",
          982247: "APR",
          982250: "MAY",
          982253: "JUN",
          993911: "AP RoadMap",
          993967: "TLisPlanResult",
          994395: "Apr 15-26",
          994571: "Month_C",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/318175/12118039/",
        child: [],
        metric: [],
        executive: "stanislav.yavtushenko@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12531199",
        name: "Find writers among essay writers",
        page: "/project/248054/12531199/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-30 18:44",
        date_end: "2024-06-19",
        max_time: 5,
        tags: {
          470849: "Content",
          640325: "Week_C",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994487: "May 29-17",
          994491: "May 20-31",
          994571: "Month_C",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/248054/12531199/",
        child: [],
        metric: ["Content"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 0.5,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0.4,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0.1,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 11.8,
          other_months: 11.3,
        },
      },
      {
        id: "12560279",
        name: "Collect semantics for slots pages",
        page: "/project/248054/12560279/",
        status: "active",
        priority: 110,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-15 16:11",
        date_end: "2024-06-17",
        max_time: 5,
        tags: {
          470849: "Content",
          491736: "Docs",
          640322: "Week_B",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994491: "May 20-31",
          994571: "Month_C",
          994643: "Jun 3-14",
        },
        team: "Management",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/248054/12560279/",
        child: [],
        metric: ["Content"],
        executive: "hrystyna.ihnatova@boosta.co",
        total_time: 2,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0.5,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 1,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0.5,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: true,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 2.2,
          other_months: 0.1,
        },
      },
      {
        id: "12320331",
        name: "Ресьоч і ТЗ на dev：Завантажаження серверних логів в систему",
        page: "/project/316281/12320331/",
        status: "active",
        priority: 105,
        user_from: {
          id: "413001",
          email: "mykola.yaremchuk@boosta.co",
          name: "Mykola Yaremchuk",
        },
        user_to: {
          id: "413001",
          email: "mykola.yaremchuk@boosta.co",
          name: "Mykola Yaremchuk",
        },
        project: {
          id: "316281",
          name: "[my] Top10CasinosGuide.com",
          page: "/project/316281/",
        },
        date_added: "2024-02-05 09:57",
        date_end: "2024-06-21",
        tags: {
          470853: "Technical",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
        },
        team: "Taikin",
        role: "TL SEO",
        task_url: "https://ra.worksection.com//project/316281/12320331/",
        child: [],
        metric: [],
        executive: "mykola.yaremchuk@boosta.co",
        total_time: 5.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0.8,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 2.4,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 2.1,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 5.8,
          other_months: 0.5,
        },
      },
      {
        id: "12597171",
        name: "Pros&Cons Element for 80 Pages",
        page: "/project/244863/12597171/",
        status: "active",
        priority: 85,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "406706",
          email: "yelyzaveta.kudriavtseva@boosta.co",
          name: "Yelyzaveta Kudriavtseva",
        },
        project: {
          id: "244863",
          name: "[sy] TestCasinos.com: Austria",
          page: "/project/244863/",
        },
        date_added: "2024-06-04 15:16",
        date_end: "2024-06-21",
        max_time: 20,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/244863/12597171/",
        child: [],
        metric: ["Content"],
        executive: "yelyzaveta.kudriavtseva@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12596019",
        name: "Properly Implement Voonix",
        page: "/project/317007/12596019/",
        status: "active",
        priority: 80,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-04 09:11",
        date_end: "2024-06-26",
        max_time: 12,
        tags: {
          382401: "Strategic",
          470852: "Conversion",
          982253: "JUN",
          994571: "Month_C",
        },
        team: "Management",
        role: "OM",
        task_url: "https://ra.worksection.com//project/317007/12596019/",
        child: [],
        metric: ["Strategic", "Conversion"],
        executive: "hanna.bilozerska@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: true,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597147",
        name: "Pros&Cons Element for 26 Pages",
        page: "/project/244859/12597147/",
        status: "done",
        priority: 75,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413719",
          email: "sofiia.filima@boosta.co",
          name: "Sofiia Filima",
        },
        project: {
          id: "244859",
          name: "[SY] TestCasinos.com: Canada - FR",
          page: "/project/244859/",
        },
        date_added: "2024-06-04 15:06",
        date_end: "2024-06-21",
        date_closed: "2024-06-12 14:46",
        max_time: 10,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/244859/12597147/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.filima@boosta.co",
        total_time: 2.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 2.3,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 2.3,
          other_months: 0,
        },
      },
      {
        id: "12597311",
        name: "Collect games data for 26 Pages",
        page: "/project/244859/12597311/",
        status: "done",
        priority: 75,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413719",
          email: "sofiia.filima@boosta.co",
          name: "Sofiia Filima",
        },
        project: {
          id: "244859",
          name: "[SY] TestCasinos.com: Canada - FR",
          page: "/project/244859/",
        },
        date_added: "2024-06-04 16:02",
        date_end: "2024-06-21",
        date_closed: "2024-06-17 14:17",
        max_time: 10,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/244859/12597311/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.filima@boosta.co",
        total_time: 8.1,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 1.7,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 4.8,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 1.5,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 8.1,
          other_months: 0,
        },
      },
      {
        id: "12597155",
        name: "Pros&Cons Element for 53 Pages",
        page: "/project/247434/12597155/",
        status: "done",
        priority: 75,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413719",
          email: "sofiia.filima@boosta.co",
          name: "Sofiia Filima",
        },
        project: {
          id: "247434",
          name: "[SY] TestCasinos.com: Canada - EN",
          page: "/project/247434/",
        },
        date_added: "2024-06-04 15:16",
        date_end: "2024-06-21",
        date_closed: "2024-06-10 11:13",
        max_time: 12,
        tags: {
          470849: "Content",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/247434/12597155/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.filima@boosta.co",
        total_time: 4.8,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 4.2,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0.7,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 4.8,
          other_months: 0,
        },
      },
      {
        id: "12597211",
        name: "Collect games data for 53 Pages",
        page: "/project/247434/12597211/",
        status: "active",
        priority: 75,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "413719",
          email: "sofiia.filima@boosta.co",
          name: "Sofiia Filima",
        },
        project: {
          id: "247434",
          name: "[SY] TestCasinos.com: Canada - EN",
          page: "/project/247434/",
        },
        date_added: "2024-06-04 15:32",
        date_end: "2024-06-21",
        max_time: 12,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/247434/12597211/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.filima@boosta.co",
        total_time: 3.3,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 2.2,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 1.1,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 3.3,
          other_months: 0,
        },
      },
      {
        id: "12552415",
        name: "Need Top Pages: отримати актуальний список",
        page: "/project/223286/12552415/",
        status: "active",
        priority: 70,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        project: {
          id: "223286",
          name: "Management Tasks",
          page: "/project/223286/",
        },
        date_added: "2024-05-13 11:14",
        date_end: "2024-06-30",
        max_time: 10,
        tags: {
          382401: "Strategic",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994491: "May 20-31",
          994571: "Month_C",
        },
        team: "Management",
        role: "TL LB",
        task_url: "https://ra.worksection.com//project/223286/12552415/",
        child: [],
        metric: ["Strategic"],
        executive: "vadym.lavrenchuk@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: true,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12576615",
        name: "Check/add postbacks for 30 brands - June",
        page: "/project/248054/12468115/12576615/",
        status: "active",
        priority: 70,
        user_from: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-05-23 17:04",
        date_end: "2024-06-19",
        time_end: "18:00",
        max_time: 15,
        tags: {
          470852: "Conversion",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994491: "May 20-31",
          994571: "Month_C",
          994643: "Jun 3-14",
        },
        parent: {
          id: "12468115",
          name: "Checking/adding postbacks - AU",
          page: "/project/248054/12468115/",
          status: "active",
          priority: "9",
        },
        team: "Management",
        role: "Account manager",
        task_url:
          "https://ra.worksection.com//project/248054/12468115/12576615/",
        child: [],
        metric: ["Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 13.8,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0.6,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0.2,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 1.5,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 2.3,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 2.5,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 3.3,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 2.8,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0.5,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0.3,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: true,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 14.2,
          other_months: 0.4,
        },
      },
      {
        id: "12596023",
        name: "Verify and complete partner data in Voonix by June 21",
        page: "/project/317007/12596019/12596023/",
        status: "active",
        priority: 70,
        user_from: {
          id: "409856",
          email: "hanna.bilozerska@boosta.co",
          name: "Hanna Bilozerska",
        },
        user_to: {
          id: "364658",
          email: "artem.budolak@boosta.co",
          name: "Artem Budolak",
        },
        project: {
          id: "317007",
          name: "Affiliate Managers",
          page: "/project/317007/",
        },
        date_added: "2024-06-04 09:12",
        date_end: "2024-06-25",
        max_time: 10,
        tags: {
          470852: "Conversion",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
        },
        parent: {
          id: "12596019",
          name: "Properly Implement Voonix",
          page: "/project/317007/12596019/",
          status: "active",
          priority: "7",
        },
        team: "Management",
        role: "Account manager",
        task_url:
          "https://ra.worksection.com//project/317007/12596019/12596023/",
        child: [],
        metric: ["Conversion"],
        executive: "artem.budolak@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: true,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12303543",
        name: "Finish setting up kasinoceske.com",
        page: "/project/297656/12303543/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "297656",
          name: "[ki] Kasinoceske.com",
          page: "/project/297656/",
        },
        date_added: "2024-01-29 16:59",
        date_end: "2024-06-28",
        max_time: 2,
        tags: {
          382401: "Strategic",
          470853: "Technical",
          640325: "Week_C",
          956642: "MAR",
          982253: "JUN",
          993967: "TLisPlanResult",
          993971: "EXisPlanResult",
          994491: "May 20-31",
          994571: "Month_C",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/297656/12303543/",
        child: [],
        metric: ["Strategic"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0.2,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0.2,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0.2,
          other_months: 0,
        },
      },
      {
        id: "12594851",
        name: "Publish 20 ready texts",
        page: "/project/248054/12594851/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-06-03 16:58",
        date_end: "2024-06-27",
        max_time: 20,
        tags: {
          470849: "Content",
          640325: "Week_C",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248054/12594851/",
        child: [],
        metric: ["Content"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12597687",
        name: "Analyze existing guest post quality and make a list of backlinks to delete",
        page: "/project/248090/12597687/",
        status: "active",
        priority: 69,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "412639",
          email: "sofiia.kozlova@boosta.co",
          name: "Sofiia Kozlova",
        },
        project: {
          id: "248090",
          name: "[ki] Leafletcasino.com",
          page: "/project/248090/",
        },
        date_added: "2024-06-04 18:26",
        date_end: "2024-06-27",
        max_time: 5,
        tags: {
          470850: "Links",
          640322: "Week_B",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "Enigma",
        role: "SEO",
        task_url: "https://ra.worksection.com//project/248090/12597687/",
        child: [],
        metric: ["Links"],
        executive: "sofiia.kozlova@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12518831",
        name: "/US/ Content Audit",
        page: "/project/297336/12518831/",
        status: "active",
        priority: 1,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "414551",
          email: "serhii.zdrok@boosta.co",
          name: "Serhii Zdrok",
        },
        project: {
          id: "297336",
          name: "[sy] TestCasinos.com: United States",
          page: "/project/297336/",
        },
        date_added: "2024-04-24 16:36",
        date_end: "2024-06-28",
        tags: {
          382401: "Strategic",
          470849: "Content",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994491: "May 20-31",
          994571: "Month_C",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/297336/12518831/",
        child: [],
        metric: ["Content", "Strategic"],
        executive: "serhii.zdrok@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12501779",
        name: "Create a Brief template for Slot Pages",
        page: "/project/317783/12597399/12501779/",
        status: "active",
        priority: 1,
        user_from: {
          id: "361139",
          email: "stanislav.yavtushenko@boosta.co",
          name: "Stanislav Yavtushenko",
        },
        user_to: {
          id: "414551",
          email: "serhii.zdrok@boosta.co",
          name: "Serhii Zdrok",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-04-16 19:04",
        date_end: "2024-06-21",
        max_time: 3,
        tags: {
          470849: "Content",
          982253: "JUN",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        parent: {
          id: "12597399",
          name: "Create Templates for 7 Page Types",
          page: "/project/317783/12597399/",
          status: "active",
          priority: "9",
        },
        team: "Jets",
        role: "Content Manager",
        task_url:
          "https://ra.worksection.com//project/317783/12597399/12501779/",
        child: [],
        metric: ["Content"],
        executive: "serhii.zdrok@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: true,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12527411",
        name: "Отримати критерії AI контенту",
        page: "/project/317783/12527411/",
        status: "active",
        priority: 1,
        user_from: {
          id: "360404",
          email: "vadym.lavrenchuk@boosta.co",
          name: "Vadym Lavrenchuk",
        },
        user_to: {
          id: "414551",
          email: "serhii.zdrok@boosta.co",
          name: "Serhii Zdrok",
        },
        project: {
          id: "317783",
          name: "[sy] TestCasinos.org",
          page: "/project/317783/",
        },
        date_added: "2024-04-29 15:23",
        date_end: "2024-06-28",
        tags: {
          470849: "Content",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994487: "May 29-17",
          994571: "Month_C",
        },
        team: "Jets",
        role: "Content Manager",
        task_url: "https://ra.worksection.com//project/317783/12527411/",
        child: [],
        metric: ["Content"],
        executive: "serhii.zdrok@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507179",
        name: "Make a list of criteria for what exactly should be presented on the slots pages",
        page: "/project/248054/12507179/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-26",
        max_time: 5,
        tags: {
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507179/",
        child: [],
        metric: [],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: true,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507191",
        name: "Manage conversion optimization of pages with clicks for AU site",
        page: "/project/248054/12507191/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-26",
        max_time: 3,
        tags: {
          470852: "Conversion",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507191/",
        child: [],
        metric: ["Conversion"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: true,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507199",
        name: "Manage the baclink strategy for AU project",
        page: "/project/248054/12507199/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-28",
        max_time: 7,
        tags: {
          470850: "Links",
          640325: "Week_C",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994571: "Month_C",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507199/",
        child: [],
        metric: ["Links"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: true,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12507231",
        name: "Proofread & edit 5 texts",
        page: "/project/248054/12507231/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415251",
          email: "anna.voitovych@boosta.co",
          name: "Anna Voitovych",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-18 15:22",
        date_end: "2024-06-27",
        max_time: 30,
        tags: {
          470849: "Content",
          982253: "JUN",
          993279: "Probation",
          993967: "TLisPlanResult",
          994571: "Month_C",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12507231/",
        child: [],
        metric: ["Content"],
        executive: "anna.voitovych@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: false,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: true,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 0,
          other_months: 0,
        },
      },
      {
        id: "12529915",
        name: "Collect semantics for bonus pages",
        page: "/project/248054/12529915/",
        status: "active",
        priority: 0,
        user_from: {
          id: "350730",
          email: "hrystyna.ihnatova@boosta.co",
          name: "Kristina Ihnatova",
        },
        user_to: {
          id: "415247",
          email: "anastasiia.hurela@boosta.co",
          name: "Anastasiia Hurela",
        },
        project: {
          id: "248054",
          name: "[ki] AussieCasinoReviewer.com",
          page: "/project/248054/",
        },
        date_added: "2024-04-30 12:57",
        date_end: "2024-06-20",
        max_time: 5,
        tags: {
          470849: "Content",
          491736: "Docs",
          640325: "Week_C",
          982250: "MAY",
          982253: "JUN",
          993967: "TLisPlanResult",
          994487: "May 29-17",
          994491: "May 20-31",
          994571: "Month_C",
          994643: "Jun 3-14",
          994647: "Jun 17-28",
        },
        team: "",
        role: "",
        task_url: "https://ra.worksection.com//project/248054/12529915/",
        child: [],
        metric: ["Content"],
        executive: "anastasiia.hurela@boosta.co",
        total_time: 0,
        _d_0: {
          weekend: true,
          deadline: false,
        },
        d_0: 0,
        _d_1: {
          weekend: true,
          deadline: false,
        },
        d_1: 0,
        _d_2: {
          weekend: false,
          deadline: false,
        },
        d_2: 0,
        _d_3: {
          weekend: false,
          deadline: false,
        },
        d_3: 0,
        _d_4: {
          weekend: false,
          deadline: false,
        },
        d_4: 0,
        _d_5: {
          weekend: false,
          deadline: false,
        },
        d_5: 0,
        _d_6: {
          weekend: false,
          deadline: false,
        },
        d_6: 0,
        _d_7: {
          weekend: true,
          deadline: false,
        },
        d_7: 0,
        _d_8: {
          weekend: true,
          deadline: false,
        },
        d_8: 0,
        _d_9: {
          weekend: false,
          deadline: false,
        },
        d_9: 0,
        _d_10: {
          weekend: false,
          deadline: false,
        },
        d_10: 0,
        _d_11: {
          weekend: false,
          deadline: false,
        },
        d_11: 0,
        _d_12: {
          weekend: false,
          deadline: false,
        },
        d_12: 0,
        _d_13: {
          weekend: false,
          deadline: false,
        },
        d_13: 0,
        _d_14: {
          weekend: true,
          deadline: false,
        },
        d_14: 0,
        _d_15: {
          weekend: true,
          deadline: false,
        },
        d_15: 0,
        _d_16: {
          weekend: false,
          deadline: false,
        },
        d_16: 0,
        _d_17: {
          weekend: false,
          deadline: false,
        },
        d_17: 0,
        _d_18: {
          weekend: false,
          deadline: false,
        },
        d_18: 0,
        _d_19: {
          weekend: false,
          deadline: true,
        },
        d_19: 0,
        _d_20: {
          weekend: false,
          deadline: false,
        },
        d_20: 0,
        _d_21: {
          weekend: true,
          deadline: false,
        },
        d_21: 0,
        _d_22: {
          weekend: true,
          deadline: false,
        },
        d_22: 0,
        _d_23: {
          weekend: false,
          deadline: false,
        },
        d_23: 0,
        _d_24: {
          weekend: false,
          deadline: false,
        },
        d_24: 0,
        _d_25: {
          weekend: false,
          deadline: false,
        },
        d_25: 0,
        _d_26: {
          weekend: false,
          deadline: false,
        },
        d_26: 0,
        _d_27: {
          weekend: false,
          deadline: false,
        },
        d_27: 0,
        _d_28: {
          weekend: true,
          deadline: false,
        },
        d_28: 0,
        _d_29: {
          weekend: true,
          deadline: false,
        },
        d_29: 0,
        _total_time: {
          all: 7.6,
          other_months: 7.6,
        },
      },
    ],
  },
};
