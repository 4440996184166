<template>
  <v-menu
    rounded="lg"
    content-class="shadow-e2-bordered"
    transition="slide-y-transition"
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-chip
        label
        outlined
        v-on="on"
        style="min-width: 86px"
        class="mr-2 d-flex justify-center"
      >
        <template v-if="loading">
          <v-progress-circular size="18" width="2" indeterminate />
        </template>
        <template v-else>
          Tools
          <v-icon small class="ml-2">mdi-hammer-wrench</v-icon>
        </template>
      </v-chip>
    </template>
    <v-card width="260">
      <v-card-text class="pa-0">
        <v-list dense>
          <template v-for="item in navItems">
            <router-link
              v-if="hasAccess(item)"
              :key="item.link"
              :to="{ name: item.link }"
              target="_blank"
              :class="`v-list-item v-list-item--link ${
                $vuetify.theme.dark ? 'theme--dark' : 'theme--light'
              }${item.separator ? ' mt-5' : ''}`"
            >
              <v-list-item-icon>
                <v-icon size="22">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </router-link>
          </template>
          <v-divider />
          <a
            v-for="link in links"
            :href="link.href"
            :key="link.href"
            target="_blank"
            class="reset-link-styles"
          >
            <v-list-item @click="() => {}">
              <v-list-item-icon>
                <v-icon size="22">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ link.title }}
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { TOOLS_MENU_ITEMS } from "@/utils/defaultData";
import { checkRouteAccess } from "@/middleware/helpers";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    navItems: TOOLS_MENU_ITEMS,
    links: [
      {
        href: "https://dct.serpinsider.com/",
        icon: "mdi-open-in-new",
        title: "DCT",
      },
    ],
  }),
  methods: {
    goTo(link, blank = false) {
      const routeData = this.$router.resolve({ name: link });
      window.open(routeData.href, blank ? "_blank" : null);
    },
    hasAccess(route) {
      return checkRouteAccess(
        route.link,
        this.$store.state.base.flat_permissions
      );
    },
  },
};
</script>
